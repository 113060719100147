//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "@/components/ModalHotakauth.vue";

export default {
  name: "UsuarioActive",
  components: { Modal },
  props: {
    usuarioProp: {
      type: Object,
    },
  },
  data() {
    return {
      usuario: null,
    };
  },
  mounted() {
    this.usuario = this.usuarioProp;
  },
  computed: {
    editUsuario() {
      return this.$store.state.usuario.editStatus.status;
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    userEdited() {
      const desactivarUsuario = {
        iduser: this.usuario.userid,
        active: false,
      };
      this.$store.dispatch("usuario/updateStatusUser", desactivarUsuario);
    },
    notificacion(titulo, mensaje, tipo) {
      this.$notify({
        title: titulo,
        message: mensaje,
        type: tipo,
      });
    },
  },
  watch: {
    editUsuario(val) {
      if (val.edited) {
        this.notificacion(
          "Correcto!",
          "El estado ha sido actualizado",
          "success"
        );
        this.hideModal();
      } else if (val.error) {
        this.notificacion(
          "Error!",
          "Ha ocurrido un error",
          "error"
        );
        this.hideModal();
      }
    },
  },
};
