//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, regex, min, is, email } from "vee-validate/dist/rules";

extend("regex", regex);
extend("is", is);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      newPassword: "",
      repeatPassword: "",
      isActive: true,
    };
  },
  mounted() {
    this.email = this.$route.query.u;
  },
  computed: {
    statusReset() {
      return this.$store.state.user.reset.status;
    },
  },
  methods: {
    submit() {
      let body = {
        newPassword: this.newPassword,
        confirmPassword: this.repeatPassword,
        user: this.email,
      };
      this.$store.dispatch("user/resetLogin", {
        body: body,
        token: this.$route.query.ost,
      });
    },
    notificacion(titl, msg, typ) {
      this.$message({
        title: titl,
        message: msg,
        type: typ,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    textError() {
      if (this.newPassword != this.repeatPassword) {
        return "Contraseña no coincide";
      } else
        return "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos";
    },
    toLogin() {
      this.$router.push({ path: "/login" });
    },
  },
  watch: {
    statusReset(val) {
      if (val.reseted) {
        this.isActive = true;
        this.notificacion("Restablecer!", "Contraseña restaurada", "success");
        this.toLogin();
      } else if (val.error) {
        this.isActive = true;
        this.notificacion("Error!", val.error, "error");
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
