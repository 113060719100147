//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    edit: {
      type: String,
      default: "",
    },
    usuarioSelected: {
      type: Object,
    },
  },
  data() {
    return {
      valueEdit: "",
      general: {
        name: "",
        password: "",
        ownerid: "",
        externalid: "",
        externalprovider: "",
      },
      listProvider: [
        {
          value: "Google",
          label: "Google",
        },
        {
          value: "GitHub",
          label: "GitHub",
        },
        {
          value: "LDAP",
          label: "LDAP",
        },
        {
          value: "Ninguno",
          label: "Ninguno",
        },
      ],
      rules: {
        name: [
          { required: true, message: "Nombre requerido", trigger: "blur" },
        ],
        ownerid: [
          { required: true, message: "OwnerId requerido", trigger: "blur" },
        ],
        externalid: [
          { required: true, message: "ExternalId requerido", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.valueEdit = this.edit;
    if (this.valueEdit === "true") {
      this.general = this.usuarioSelected;
    }
  },
  computed: {
    newUsuario() {
      return this.$store.state.usuario.new.status;
    },
  },
  methods: {
    saveNuevoUsuario(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("usuario/newUsuario", this.general);
        }
      });
    },
    notificacion(titulo, mensaje, tipo) {
      this.$notify({
        title: titulo,
        message: mensaje,
        type: tipo,
      });
    },
  },
  watch: {
    newUsuario() {
      if (this.newUsuario.created) {
        if (this.$store.state.usuario.new.data) {
          this.notificacion("Creado", "Ha sido creado correctamente", "success");
          this.$router.push("/usuarios/listado");
        }
      } else if (this.newUsuario.error) {
        this.notificacion(
          "Error!",
          "Ha ocurrido un error al crear el usuario",
          "error"
        );
      }
    },
  },
};
