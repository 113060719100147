var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-6 col-lg-12" },
        [
          _c(
            "card",
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Collapsible Accordion"),
                ]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("On white card"),
                ]),
              ]),
              _c(
                "el-collapse",
                [
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "1" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "2" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "3" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xl-6 col-lg-12" },
        [
          _c(
            "card",
            { staticClass: "card-plain" },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Collapsible Accordion"),
                ]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("On plain card"),
                ]),
              ]),
              _c(
                "el-collapse",
                [
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "1" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "2" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { title: "Default Collapsible Item 1", name: "3" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-6 col-lg-12" },
        [
          _c(
            "card",
            { attrs: { title: "Tabs", subTitle: "Plain text tabs" } },
            [
              _c(
                "vue-tabs",
                { attrs: { value: "Description" } },
                [
                  _c("v-tab", { attrs: { title: "Info" } }, [
                    _c("p", [
                      _vm._v(
                        "Agency is a group of professional individuals looking to create amazing pieces of clothing. We have studied at the best schools of design, we have tailored the suits for the most stylish men in the industry, we are what you need!"
                      ),
                    ]),
                  ]),
                  _c("v-tab", { attrs: { title: "Account" } }, [
                    _c("p", [
                      _vm._v(
                        "We are Houses Inc., a group of architects and interior designers based in Chicago and operating for clients worldwide. We’ve been designing stunningly beautiful houses and making clients happy for years."
                      ),
                    ]),
                  ]),
                  _c("v-tab", { attrs: { title: "Style" } }, [
                    _c("p", [
                      _vm._v(
                        "Explore a wide variety of styles, personalise your finishes, and let us design the perfect home for you. It's what we do best and you can see proof in the products and reviews below."
                      ),
                    ]),
                  ]),
                  _c("v-tab", { attrs: { title: "Settings" } }, [
                    _vm._v(
                      " Explore a wide Houses Inc., a group of architects and interior designers based in Chicago and operating for clients worldwide. We’ve been designing stunningly beautiful houses and making clients happy for years. "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xl-6 col-lg-12" },
        [
          _c(
            "card",
            { attrs: { title: "Tabs & Icons", subTitle: "Tabs with icons" } },
            [
              _c(
                "vue-tabs",
                [
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        title: "Info",
                        icon: "nc-icon nc-single-copy-04",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Agency is a group of professional individuals looking to create amazing pieces of clothing. We have studied at the best schools of design, we have tailored the suits for the most stylish men in the industry, we are what you need!"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: { title: "Account", icon: "nc-icon nc-circle-09" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "We are Houses Inc., a group of architects and interior designers based in Chicago and operating for clients worldwide. We’ve been designing stunningly beautiful houses and making clients happy for years."
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        title: "Style",
                        icon: "nc-icon nc-ruler-pencil",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Explore a wide variety of styles, personalise your finishes, and let us design the perfect home for you. It's what we do best and you can see proof in the products and reviews below."
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        title: "Settings",
                        icon: "nc-icon nc-settings-90",
                      },
                    },
                    [
                      _vm._v(
                        " Explore a wide Houses Inc., a group of architects and interior designers based in Chicago and operating for clients worldwide. We’ve been designing stunningly beautiful houses and making clients happy for years. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }