var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress", style: "height: " + _vm.height + "px" },
    [
      _vm.values.length === 0
        ? _c(
            "div",
            {
              staticClass: "progress-bar",
              class: _vm.computedClasses,
              style: "width: " + _vm.value + "%;",
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.value,
                "aria-valuemin": "0",
                "aria-valuemax": "100",
              },
            },
            [
              _vm._t("default", function () {
                return [
                  _vm.showText
                    ? _c("span", [_vm._v(" " + _vm._s(_vm.value) + "% ")])
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._l(_vm.values, function (progress) {
            return _c(
              "div",
              {
                key: progress.value,
                staticClass: "progress-bar",
                class: _vm.progressClasses(progress),
                style: "width: " + progress.value + "%;",
              },
              [
                progress.text
                  ? _c("span", [_vm._v(" " + _vm._s(progress.text) + " ")])
                  : _vm._e(),
              ]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }