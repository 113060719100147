var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary ms-3",
              attrs: { type: "button" },
              on: { click: _vm.nuevoUsuario },
            },
            [_vm._v(" Agregar Usuario ")]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row mt-2 mb-2" },
        [
          _c("div", { staticClass: "col" }),
          _c("el-input", {
            staticClass: "col-2",
            attrs: { placeholder: "Buscar..." },
            on: { input: _vm.buscarUsuario },
            model: {
              value: _vm.busqueda,
              callback: function ($$v) {
                _vm.busqueda = $$v
              },
              expression: "busqueda",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row ps-3 pe-3 pt-2" },
        [
          _c(
            "el-table",
            {
              staticClass: "col",
              attrs: { data: _vm.allUsuarios, height: "543" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "Usuario" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ownerid", label: "OwnerId" },
              }),
              _c("el-table-column", {
                attrs: { prop: "active", label: "Active" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.active
                          ? _c("i", {
                              staticClass: "el-icon-check",
                              on: {
                                click: function ($event) {
                                  return _vm.empleadoDetalle(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "Acciones", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-key hand m-l",
                          on: {
                            click: function ($event) {
                              return _vm.editPassword(scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-edit hand",
                          on: {
                            click: function ($event) {
                              return _vm.usuarioDetalle(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "active", fixed: "right", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.desactivarUsuario(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value: scope.row.active,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "active", $$v)
                                },
                                expression: "scope.row.active",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showModalEdit
        ? _c("edit-password", {
            attrs: { userItemProp: _vm.user },
            on: { hide: _vm.hideModal },
          })
        : _vm._e(),
      _vm.activarUsuario
        ? _c("usuario-active", {
            attrs: { usuarioProp: _vm.user },
            on: { hide: _vm.hideModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "nav",
      { staticClass: "col-10", attrs: { "aria-label": "breadcrumb" } },
      [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            {
              staticClass: "breadcrumb-item fs-3",
              attrs: { "aria-current": "page" },
            },
            [_vm._v(" Lista de usuarios ")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }