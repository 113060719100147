import createAxios from "./axiosHttpMarconi";

function getMenus() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/auth/menus`;
  return axios.get(urlPath).then((response) => {
    localStorage.setItem("roles-menu", JSON.stringify(response.franquicia));
    return response;
  });
}

function getForbiddenPages(pages, user) {
  if (!pages || !user) return [];

  if (user.roles.includes("ADMIN")) return [];

  const forbiddenPages = [];
  for (const page of pages) {
    if (page.roles.length == 0) continue;

    if (!user.rolesMenu) {
      forbiddenPages.push(page.key);
      continue;
    }

    if (page.roles.some((rol) => user.rolesMenu.includes(rol))) continue;

    forbiddenPages.push(page.key);
  }
  return forbiddenPages;
}

export const rolesService = {
  getMenus,
  getForbiddenPages,
};
