import createAxios from './axiosHttpMarconi';

export const exportacionService = {
  nuevaExportacionContable, getExportacionContable, updateExportacionContable
};
function nuevaExportacionContable(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/configuracion_contable`;
  return axios.post(urlPath, body);
}

function getExportacionContable(idFranquicia){
  const axios = createAxios();
  let urlPath = `/franquicia/${idFranquicia}/configuracion_contable`;
  return axios.get(urlPath);
}

function updateExportacionContable(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/configuracion_contable/${user.extraData.franquiciaId}`;
  return axios.put(urlPath, body);
}