var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "card-stats" }, [
    _c("div", { staticClass: "row" }, [
      _vm.$slots.header
        ? _c("div", { staticClass: "col-5" }, [
            _c("div", { staticClass: "icon-big" }, [_vm._t("header")], 2),
          ])
        : _vm._e(),
      _vm.$slots.content || _vm.title
        ? _c("div", { staticClass: "col-7" }, [
            _c(
              "div",
              { staticClass: "numbers" },
              [
                _vm._t("content", function () {
                  return [
                    _vm.subTitle
                      ? _c("p", { staticClass: "card-category" }, [
                          _vm._v(_vm._s(_vm.subTitle)),
                        ])
                      : _vm._e(),
                    _vm.title
                      ? _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
    _vm.$slots.footer
      ? _c(
          "div",
          { staticClass: "stats", attrs: { slot: "footer" }, slot: "footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }