var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "card",
        [
          _c("template", { slot: "header" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v("Notifications")]),
            _c("p", { staticClass: "card-category" }, [_vm._v("Vue NotifyJs")]),
          ]),
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("h5", [_vm._v("Notifications Style")]),
                  _c("l-alert", { attrs: { type: "info" } }, [
                    _vm._v(" This is a plain notification "),
                  ]),
                  _c("l-alert", { attrs: { type: "info", dismissible: "" } }, [
                    _vm._v(" This is a notification with close button. "),
                  ]),
                  _c(
                    "l-alert",
                    {
                      attrs: {
                        type: "info",
                        dismissible: "",
                        icon: "nc-icon nc-bell-55",
                      },
                    },
                    [
                      _vm._v(
                        " This is a notification with close button and icon. "
                      ),
                    ]
                  ),
                  _c(
                    "l-alert",
                    {
                      attrs: {
                        type: "info",
                        dismissible: "",
                        icon: "nc-icon nc-bell-55",
                      },
                    },
                    [
                      _vm._v(
                        " This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("h5", [_vm._v("Notification states")]),
                  _c("l-alert", { attrs: { type: "info", dismissible: "" } }, [
                    _c("span", [
                      _c("b", [_vm._v(" Info - ")]),
                      _vm._v(
                        ' This is a regular notification made with ".alert-info"'
                      ),
                    ]),
                  ]),
                  _c(
                    "l-alert",
                    { attrs: { type: "success", dismissible: "" } },
                    [
                      _c("span", [
                        _c("b", [_vm._v(" Success - ")]),
                        _vm._v(
                          ' This is a regular notification made with ".alert-success"'
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "l-alert",
                    { attrs: { type: "warning", dismissible: "" } },
                    [
                      _c("span", [
                        _c("b", [_vm._v(" Warning - ")]),
                        _vm._v(
                          ' This is a regular notification made with ".alert-warning"'
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "l-alert",
                    { attrs: { type: "danger", dismissible: "" } },
                    [
                      _c("span", [
                        _c("b", [_vm._v(" Danger - ")]),
                        _vm._v(
                          ' This is a regular notification made with ".alert-danger"'
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("br"),
            _c("br"),
            _c("div", { staticClass: "places-buttons" }, [
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-6 text-center" }, [
                  _c("h5", [
                    _vm._v("Notifications Places "),
                    _c("p", { staticClass: "category" }, [
                      _vm._v("Click to view notifications"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("top", "left")
                        },
                      },
                    },
                    [_vm._v("Top Left")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("top", "center")
                        },
                      },
                    },
                    [_vm._v("Top Center")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("top", "right")
                        },
                      },
                    },
                    [_vm._v("Top Right")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("bottom", "left")
                        },
                      },
                    },
                    [_vm._v("Bottom Left")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("bottom", "center")
                        },
                      },
                    },
                    [_vm._v("Bottom Center")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-block",
                      on: {
                        click: function ($event) {
                          return _vm.notifyVue("bottom", "right")
                        },
                      },
                    },
                    [_vm._v("Bottom Right")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "category" }, [
      _vm._v(
        "Easy and accessible notifications everywhere in your app. Handcrafted by "
      ),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            rel: "noopener",
            href: "https://github.com/cristijora",
          },
        },
        [_vm._v("Cristi Jora")]
      ),
      _vm._v(". Please check out the "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/cristijora/vue-notifyjs",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("github repository")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }