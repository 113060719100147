//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'selector-direccion',
  components: {
  },
  props: {
    type: {
      type: String,
      default: 'reecogida',
      description: 'Tipo de direcciones'
    },
  },
  data() {
    return {
      direcciones: [],
    };
  },
  methods: {
  }  
}
