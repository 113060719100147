var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    on: { hide: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h5", { staticClass: "modal-title" }, [_vm._v("Advertencia")]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "contenedor" }, [
              _c("h5", [_vm._v("¿Esta seguro que desea activar el usuario?")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.hideModal },
              },
              [_vm._v(" Cancelar ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.userEdited },
              },
              [_vm._v(" Aceptar ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }