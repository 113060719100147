//
//
//
//
//
//
//
//
//

import AuthLayout from 'src/pages/Dashboard/Pages/AuthLayout.vue'

export default {
  components: {
    AuthLayout
  }
}
