import { documentacionService } from "@/_services";

export const documentacion = {
  namespaced: true,
  state: {
    list: {
      data: {},
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getDocumentacionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getDocumentacionesSuccess(state, res) {
      state.list.data = res;
      state.list.status = {
        loaded: true,
      };
    },
    getDocumentacionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    downloadDocumentacionRequest(state) {
      state.entity.status = {
        downloading: true,
      };
    },
    downloadDocumentacionError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    downloadDocumentacionSuccess(state, res) {
      state.entity.data = res;
      state.entity.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getDocumentaciones({ commit }, request) {
      commit("getDocumentacionesRequest");
      documentacionService
        .getDocumentaciones(request)
        .then((documentaciones) => {
          commit("getDocumentacionesSuccess", documentaciones);
        })
        .catch((error) => {
          commit("getDocumentacionesError", error);
        });
    },
    downloadDocumentacion({ commit }, body) {
      commit("downloadDocumentacionRequest");
      documentacionService
        .downloadDocumentacion(body)
        .then((response) => {
          commit("downloadDocumentacionSuccess", response);
        })
        .catch((error) => {
          commit("downloadDocumentacionError", error);
        });
    },
  },
};
