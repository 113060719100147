import { apiServer, apiServerV2 } from "../globalVars";

// eslint-disable-next-line no-undef
const axios = require("axios").default;

function createAxios() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {};
  if (user && user.accessToken) {
    headers.Authorization = `Bearer ${user.accessToken}`;
  }
  const instance = axios.create({
    baseURL: apiServer,
    headers,
  });

  instance.interceptors.response.use(
    (response) => Promise.resolve(response.data),
    (error) => {
      if (error.response) {
        if ([401].includes(error.response.status)) {
          localStorage.removeItem("user");
          localStorage.removeItem("franquicia");
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        } else if ([403].includes(error.response.status)) {
          location.hash = "#/franquicia/forbidden";
        }
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject("Error al acceder a los servicios API");
      }
    }
  );
  return instance;
}

function createAxiosV2() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {};
  if (user && user.accessToken) {
    headers.Authorization = `Bearer ${user.accessToken}`;
  }
  const instance = axios.create({
    baseURL: apiServerV2,
    headers,
  });

  instance.interceptors.response.use(
    (response) => Promise.resolve(response.data),
    (error) => {
      if (error.response) {
        if ([403, 401].includes(error.response.data.error.status)) {
          localStorage.removeItem("user");
          localStorage.removeItem("franquicia");
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        }
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject("Error al acceder a los servicios API");
      }
    }
  );

  return instance;
}

function createAxiosHeaders() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {};
  if (user && user.accessToken) {
    headers.Authorization = `Bearer ${user.accessToken}`;
  }
  const instance = axios.create({
    baseURL: apiServer,
    headers,
  });

  instance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      if (error.response) {
        if ([401].includes(error.response.status)) {
          localStorage.removeItem("user");
          localStorage.removeItem("franquicia");
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        } else if ([403].includes(error.response.status)) {
          location.hash = "#/franquicia/forbidden";
        }
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject("Error al acceder a los servicios API");
      }
    }
  );
  return instance;
}

export default createAxios;
export { createAxios, createAxiosV2, createAxiosHeaders };
