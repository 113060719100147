import createAxios from "./axiosHttpMarconi";

function getFichaCliente(clienteId) {
  return new Promise((resolve, reject) => {
    const axios = createAxios();
    let urlPath = `/cliente/${clienteId}`;
    return axios
      .get(urlPath)
      .then((response) => {
        resolve(response);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}
function loadCliente(clienteId) {
  return new Promise((resolve, reject) => {
    getFichaCliente(clienteId)
      .then((cliente) => {
        localStorage.setItem("cliente", JSON.stringify(cliente));
        resolve(cliente);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function newCliente(body) {
  const axios = createAxios();
  let urlPath = "/cliente";
  return axios.post(urlPath, body);
}

function cambiarEstadoCliente(idCliente) {
  const axios = createAxios();
  let urlPath = `/cliente/${idCliente}/estado`;
  return axios.put(urlPath);
}

function updateCliente(body) {
  const axios = createAxios();
  let urlPath = `/cliente/${body._id}`;
  return axios.put(urlPath, body);
}

function desactivarCliente(id) {
  const axios = createAxios();
  const urlPath = `/cliente/${id}`;
  return axios.delete(urlPath);
}

function getRMAFile(clienteId, fileId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquicia/${user.extraData.franquiciaId}/cliente/${clienteId}/rma/file/${fileId}`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function deleteRMAFile(clienteId, fileId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquicia/${user.extraData.franquiciaId}/cliente/${clienteId}/rma/file/${fileId}`;
  return axios.delete(urlPath);
}

export const clienteService = {
  getFichaCliente,
  newCliente,
  updateCliente,
  desactivarCliente,
  loadCliente,
  cambiarEstadoCliente,
  getRMAFile,
  deleteRMAFile,
};
