import { envioService } from "@/_services";
import { blob } from "d3";

export const envio = {
  namespaced: true,
  state: {
    convertir:{
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    dirHabituales: {
      entrega: {},
      recogida: {},
      rma: {},
      status: {
        entrega: {},
        recogida: {},
        rma: {},
      },
    },
    responseModificar: {
      data: {},
      status: {},
    },
    estadosEnvio: {
      data: {},
      status: {},
    },
    envio: {
      data: [],
      status: {},
    },
    newEventoEnvio: {
      data: {},
      status: {},
    },
    responseJustificante: {
      data: {},
      status: {},
    },
    justificanteServicio: {
      data: {},
      status: {},
    },
    justificanteRecogida: {
      data: {},
      status: {},
    },
    justificanteEntrega: {
      data: {},
      status: {},
    },
    envioProgramado: {
      data: {},
      status: {},
    },
    paperLess: {
      data: {},
      status: {},
    },
    paperLessFile: {
      data: {},
      status: {},
    },
    downloadPaperless:{
      data: {},
      status: {},
    },
    deleteDownloadPaperless:{
      data: {},
      status: {},
    }
  },
  mutations: {
    sendEnvioPaperlessRequest(state) {
      state.deleteDownloadPaperless.status = {
        creating: true,
      };
    },
    sendPaperlessError(state, error) {
      state.deleteDownloadPaperless.data = null;
      state.deleteDownloadPaperless.status = {
        error,
      };
    },
    sendPaperlessSuccess(state, envio) {
      state.deleteDownloadPaperless.data = envio;
      state.deleteDownloadPaperless.status = {
        created: true,
      };
    },
    downloadPaperlessRequest(state) {
      state.downloadPaperless.status = {
        loading: true,
      };
    },
    downloadPaperlessError(state, error) {
      state.downloadPaperless.data = null;
      state.downloadPaperless.status = {
        error,
      };
    },
    downloadPaperlessSuccess(state, paperless) {
      state.downloadPaperless.data = paperless;
      state.downloadPaperless.status = {
        loaded: true,
      };
    },
    getPaperLessFileRequest(state) {
      state.paperLessFile.status = {
        downloading: true,
      };
    },
    getPaperLessFileError(state, error) {
      state.paperLessFile.data = null;
      state.paperLessFile.status = {
        error,
      };
    },
    getPaperLessFileSuccess(state, ficha) {
      state.paperLessFile.data = ficha;
      state.paperLessFile.status = {
        downloaded: true,
      };
    },
    getPaperLessRequest(state) {
      state.paperLess.status = {
        loading: true,
      };
    },
    getPaperLessSuccess(state, env) {
      state.paperLess.data = env;
      state.paperLess.status = {
        loaded: true,
      };
    },
    getPaperLessError(state, error) {
      state.paperLess.data = null;
      state.paperLess.status = {
        error,
      };
    },

    newEventoEnvioRequest(state) {
      state.newEventoEnvio.status = {
        creating: true,
      };
    },
    newEventoEnvioError(state, error) {
      state.newEventoEnvio.data = null;
      state.newEventoEnvio.status = {
        error,
      };
    },
    newEventoEnvioSuccess(state, envio) {
      state.newEventoEnvio.data = envio;
      state.newEventoEnvio.status = {
        created: true,
      };
    },
    getEnvioRequest(state) {
      state.envio.status = {
        loading: true,
      };
    },
    getEnvioSuccess(state, env) {
      state.envio.data = env;
      state.envio.status = {
        loaded: true,
      };
    },
    getEnvioError(state, error) {
      state.envio.data = null;
      state.envio.status = {
        error,
      };
    },
    newEnvioRequest(state) {
      state.entity.status = {
        creating: true,
      };
    },
    newEnvioError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    newEnvioSuccess(state, envio) {
      state.entity.data = envio;
      state.entity.status = {
        created: true,
      };
    },
    getDirHabitualesRequest(state) {
      state.dirHabituales.status = {
        loading: true,
      };
    },
    getDirHabitualesError(state, error) {
      state.dirHabituales.data = null;
      state.dirHabituales.status = {
        error,
      };
    },
    getDirHabitualesSuccess(state, direccion) {
      state.dirHabituales.data = direccion;
      state.dirHabituales.status = {
        loaded: true,
      };
    },
    getDirHabitualesSuccessByTipo(state, response) {
      if (response.tipo) {
        if (response.tipo == "ENTREGA") {
          state.dirHabituales.entrega = response.res;
          state.dirHabituales.status.entrega = {
            loaded: true,
          };
        } else if (response.tipo == "RMA") {
          state.dirHabituales.rma = response.res;
          state.dirHabituales.status.entrega = {
            loaded: true,
          };
        } else {
          state.dirHabituales.recogida = response.res;
          state.dirHabituales.status.recogida = {
            loaded: true,
          };
        }
      }
    },
    getDirHabitualesErrorByTipo(state, response) {
      if (response.tipo) {
        if (response.tipo == "RECOGIDA") {
          state.dirHabituales.status.recogida = {
            error: response.error,
          };
        } else {
          state.dirHabituales.status.entrega = {
            error: response.error,
          };
        }
      }
    },
    getDirHabitualesRequestByTipo(state, tipo) {
      if (tipo) {
        if (tipo == "RECOGIDA") {
          state.dirHabituales.status.recogida = {
            loading: true,
          };
        } else {
          state.dirHabituales.status.entrega = {
            loading: true,
          };
        }
      }
    },
    convertirLocalError(state, error) {
      state.convertir.data = null;
      state.convertir.status = {
        error,
      };
    },
    convertirLocalSuccess(state, envios) {
      state.convertir.data = envios;
      state.convertir.status = {
        loaded: true,
      };
    },
    convertirLocalRequest(state) {
      state.convertir.status = {
        loading: true,
      };
    },
    modificarEstadoError(state, error) {
      state.responseModificar.data = null;
      state.responseModificar.status = {
        error,
      };
    },
    modificarEstadoSuccess(state, estados) {
      state.responseModificar.data = estados;
      state.responseModificar.status = {
        loaded: true,
      };
    },
    modificarEstadoRequest(state) {
      state.responseModificar.status = {
        loading: true,
      };
    },
    getEstadosEnvioError(state, error) {
      state.estadosEnvio.data = null;
      state.estadosEnvio.status = {
        error,
      };
    },
    getEstadosEnvioSuccess(state, estados) {
      state.estadosEnvio.data = estados;
      state.estadosEnvio.status = {
        loaded: true,
      };
    },
    getEstadosEnvioRequest(state) {
      state.estadosEnvio.status = {
        loading: true,
      };
    },
    getJustificanteRequest(state) {
      state.responseJustificante.status = {
        loading: true,
      };
      state.responseJustificante.data = {};
    },
    getJustificanteSuccess(state, response) {
      state.responseJustificante.data = response;
      state.responseJustificante.status = {
        loaded: true,
      };
    },
    getJustificanteError(state, error) {
      state.responseJustificante.data = null;
      state.responseJustificante.status = {
        error,
      };
    },
    getJustificanteServicioRequest(state) {
      state.justificanteServicio.status = {
        loading: true,
      };
    },
    getJustificanteServicioSuccess(state, justificante) {
      state.justificanteServicio.data = justificante;
      state.justificanteServicio.status = {
        loaded: true,
      };
    },
    getJustificanteServicioError(state, error) {
      state.justificanteServicio.data = null;
      state.justificanteServicio.status = {
        error,
      };
    },
    getJustificanteRecogidaRequest(state) {
      state.justificanteRecogida.status = {
        loading: true,
      };
    },
    getJustificanteRecogidaSuccess(state, justificante) {
      state.justificanteRecogida.data = justificante;
      state.justificanteRecogida.status = {
        loaded: true,
      };
    },
    getJustificanteRecogidaError(state, error) {
      state.justificanteRecogida.data = null;
      state.justificanteRecogida.status = {
        error,
      };
    },

    getJustificanteEntregaRequest(state) {
      state.justificanteEntrega.status = {
        loading: true,
      };
    },
    getJustificanteEntregaSuccess(state, justificante) {
      state.justificanteEntrega.data = justificante;
      state.justificanteEntrega.status = {
        loaded: true,
      };
    },
    getJustificanteEntregaError(state, error) {
      state.justificanteEntrega.data = null;
      state.justificanteEntrega.status = {
        error,
      };
    },
    envioProgramadoRequest(state) {
      state.envioProgramado.status = {
        creating: true,
      };
    },
    envioProgramadoError(state, error) {
      state.envioProgramado.data = null;
      state.envioProgramado.status = {
        error,
      };
    },
    envioProgramadoSuccess(state, envio) {
      state.envioProgramado.data = envio;
      state.envioProgramado.status = {
        created: true,
      };
    },
  },
  actions: {
    downloadPaperless({ commit }, body) {
      commit("downloadPaperlessRequest");
      envioService
        .downloadPaperless(body)
        .then((paperless) => {
          commit("downloadPaperlessSuccess", paperless);
        })
        .catch((error) => {
          commit("downloadPaperlessError", error);
        });
    },
    eliminarPaperless({ commit }, body) {
      commit("sendEnvioPaperlessRequest");
      envioService
        .eliminarPaperless(body)
        .then((paperless) => {
          commit("sendPaperlessSuccess", paperless);
        })
        .catch((error) => {
          commit("sendPaperlessError", error);
        });
    },
    getPaperLessFile({ commit }, req) {
      commit("getPaperLessFileRequest");
      envioService
        .getPaperLessFile(req.cliente, req.procesoId)
        .then((env) => {
          commit("getPaperLessFileSuccess", env);
        })
        .catch((error) => {
          commit("getPaperLessFileError", error);
        });
    },
    getPaperLessEnvio({ commit }, envioId) {
      commit("getPaperLessRequest");
      envioService
        .getPaperLessEnvio(envioId)
        .then((env) => {
          commit("getPaperLessSuccess", env);
        })
        .catch((error) => {
          commit("getPaperLessError", error);
        });
    },
    getEnvio({ commit }, envioId) {
      commit("getEnvioRequest");
      envioService
        .getEnvio(envioId)
        .then((env) => {
          commit("getEnvioSuccess", env);
        })
        .catch((error) => {
          commit("getEnvioError", error);
        });
    },
    sendEnvio({ commit }, body) {
      commit("newEnvioRequest");
      envioService
        .sendEnvio(body)
        .then((env) => {
          commit("newEnvioSuccess", env);
        })
        .catch((error) => {
          commit("newEnvioError", error);
        });
    },
    getDirHabituales({ commit }, body) {
      commit("getDirHabitualesRequest");
      envioService
        .getDirHabituales(body)
        .then((env) => {
          commit("getDirHabitualesSuccess", env);
        })
        .catch((error) => {
          commit("getDirHabitualesError", error);
        });
    },
    getDirHabitualesByTipo({ commit }, body) {
      commit("getDirHabitualesRequestByTipo", body.tipo);
      envioService
        .getDirHabituales(body)
        .then((env) => {
          commit("getDirHabitualesSuccessByTipo", {
            res: env,
            tipo: body.tipo,
          });
        })
        .catch((error) => {
          commit("getDirHabitualesErrorByTipo", {
            error: error,
            tipo: body.tipo,
          });
        });
    },
    modificarEstado({ commit }, body) {
      commit("modificarEstadoRequest");
      envioService
        .modificarEstado(body)
        .then((env) => {
          commit("modificarEstadoSuccess", env);
        })
        .catch((error) => {
          commit("modificarEstadoError", error);
        });
    },
    convertirLocal({ commit }, idEnvio) {
      commit("convertirLocalRequest");
      envioService
        .convertirLocal(idEnvio)
        .then((env) => {
          commit("convertirLocalSuccess", env);
        })
        .catch((error) => {
          commit("convertirLocalError", error);
        });
    },
    getEstadosEnvio({ commit }, body) {
      commit("getEstadosEnvioRequest");
      envioService
        .getEstadosEnvio(body)
        .then((env) => {
          commit("getEstadosEnvioSuccess", env);
        })
        .catch((error) => {
          commit("getEstadosEnvioError", error);
        });
    },
    newEventoEnvio({ commit }, req) {
      commit("newEventoEnvioRequest");
      envioService
        .newEventoEnvio(req._id, req.body)
        .then((env) => {
          commit("newEventoEnvioSuccess", env);
        })
        .catch((error) => {
          commit("newEventoEnvioError", error);
        });
    },
    getJustificanteEntrega({ commit }, request) {
      commit("getJustificanteRequest");
      envioService
        .getJustificanteEntrega(request)
        .then((justificante) => {
          commit("getJustificanteSuccess", justificante);
        })
        .catch((error) => {
          commit("getJustificanteError", error);
        });
    },
    getJustificanteServicio({ commit }, request) {
      commit("getJustificanteServicioRequest");
      envioService
        .getJustificanteServicio(request)
        .then((justificante) => {
          commit("getJustificanteServicioSuccess", justificante);
        })
        .catch((error) => {
          commit("getJustificanteServicioError", error);
        });
    },
    getJustificanteRecogida({ commit }, request) {
      commit("getJustificanteRecogidaRequest");
      envioService
        .getJustificanteRecogida(request)
        .then((justificante) => {
          commit("getJustificanteRecogidaSuccess", justificante);
        })
        .catch((error) => {
          commit("getJustificanteRecogidaError", error);
        });
    },
    sendEnvioProgramado({ commit }, body) {
      commit("envioProgramadoRequest");
      envioService
        .sendEnvioProgramado(body)
        .then((env) => {
          commit("envioProgramadoSuccess", env);
        })
        .catch((error) => {
          commit("envioProgramadoError", error);
        });
    },
  },
};
