//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'card',
  props: {
    title: String,
    subTitle: String
  }
}
