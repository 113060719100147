var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-4")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-4")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._m(2),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-4")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._m(3),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-lg-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-lg-4")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-4" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-lg-4")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._m(4),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 col-lg-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-6 col-lg-3")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6 col-lg-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-6 col-lg-3")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6 col-lg-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-6 col-lg-3")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6 col-lg-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-sm-6 col-lg-3")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._m(5),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-3")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 offset-md-6" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-3 offset-md-6")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4 offset-md-1" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-4 offset-md-1")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4 offset-md-2" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-4 offset-md-2")]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-6 offset-md-3" },
          [
            _c("card", [
              _c("div", { staticClass: "text-center" }, [
                _c("code", [_vm._v("col-md-6 offset-md-3")]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("h4", { staticClass: "card-title" }, [_vm._v("Paragraphs")]),
      _c("card", [
        _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h3", [_vm._v("Some card-title Here")]),
              _c("p", [
                _vm._v(
                  'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h3", [_vm._v("Another card-title Here")]),
              _c("p", [
                _vm._v(
                  'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                ),
              ]),
            ]),
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Some card-title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Another card-title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Another card-title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
          ]),
          _c("br"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("h3", [_vm._v("Some card-title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-sm-8" }, [
              _c("h3", [_vm._v("Another card-title Here")]),
              _c("p", [
                _vm._v(
                  "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-card-title" }, [
      _vm._v("XS Grid "),
      _c("small", [_vm._v("Always Horizontal")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-title" }, [
      _vm._v("SM Grid "),
      _c("small", [_vm._v("Collapsed at 768px")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-title" }, [
      _vm._v("MD Grid "),
      _c("small", [_vm._v("Collapsed at 992px")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-title" }, [
      _vm._v("LG Grid "),
      _c("small", [_vm._v("Collapsed at 1200px")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-title" }, [
      _vm._v("Mixed Grid "),
      _c("small", [_vm._v("Showing different sizes on different screens")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "card-title" }, [
      _vm._v("Offset Grid "),
      _c("small", [_vm._v("Adding some space when needed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }