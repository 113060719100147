import createAxios from './axiosHttpMarconi';
import { franquiciaService } from './franquicia.service';

function getContactoDireccion(clienteId,contactoId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/cliente/${clienteId}/contacto/${contactoId}`;
  return axios.get(urlPath);
}
function newContactoDireccion(clienteId,body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/cliente/${clienteId}/contacto`;
  return axios.post(urlPath, body);
}
function updateContactoDireccion(clienteId,contactoId,body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/cliente/${clienteId}/contacto/${contactoId}`;
  return axios.put(urlPath, body);
}
export const libretaContactoService = {
  getContactoDireccion,
  newContactoDireccion,
  updateContactoDireccion
};


