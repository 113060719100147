import { paradaService } from "@/_services";

export const parada = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    envios: {
      data: [],
      status: {},
    },
    manifiesto: {
      data: null,
      status: {},
    },
    etiqueta: {
      data: null,
      status: {},
    },
    email: {
      data: {},
      status: {},
    },
    anular: {
      data: {},
      status: {},
    },
    estados: {
      data: [],
      status: {},
    },
    images: {
      data: [],
      status: {},
    },
    justificante: {
      data: null,
      status: {},
    },
    paradaId: {
      data: null,
      status: {},
    },
    entityRecogida: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getParadaInfoRequest(state) {
      state.paradaId.status = {
        loading: true,
      };
    },
    getParadaInfoError(state, error) {
      state.paradaId.data = [];
      state.paradaId.status = {
        error,
      };
    },
    getParadaInfoSuccess(state, response) {
      state.paradaId.data = response;
      state.paradaId.status = {
        loaded: true,
      };
    },

    getParadasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getParadasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getParadasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getEnviosByParadaRequest(state) {
      state.envios.status = {
        loading: true,
      };
    },
    getEnviosByParadaError(state, error) {
      state.envios.data = [];
      state.envios.status = {
        error,
      };
    },
    getEnviosByParadaSuccess(state, response) {
      state.envios.data = response;
      state.envios.status = {
        loaded: true,
      };
    },
    getManifiestoByParadaRequest(state) {
      state.manifiesto.status = {
        loading: true,
      };
    },
    getManifiestoByParadaError(state, error) {
      state.manifiesto.data = [];
      state.manifiesto.status = {
        error,
      };
    },
    getManifiestoByParadaSuccess(state, response) {
      state.manifiesto.data = response;
      state.manifiesto.status = {
        loaded: true,
      };
    },
    getEtiquetaPDFByParadaRequest(state) {
      state.etiqueta.status = {
        loading: true,
      };
    },
    getEtiquetaPDFByParadaError(state, error) {
      state.etiqueta.data = [];
      state.etiqueta.status = {
        error,
      };
    },
    getEtiquetaPDFByParadaSuccess(state, response) {
      state.etiqueta.data = response;
      state.etiqueta.status = {
        loaded: true,
      };
    },
    sendEmailEtiquetasByParadaRequest(state) {
      state.email.status = {
        sending: true,
      };
    },
    sendEmailEtiquetasByParadaError(state, error) {
      state.email.data = [];
      state.email.status = {
        error,
      };
    },
    sendEmailEtiquetasByParadaSuccess(state, response) {
      state.email.data = response;
      state.email.status = {
        sended: true,
      };
    },
    voidParadaRequest(state) {
      state.anular.status = {
        canceling: true,
      };
    },
    voidParadaError(state, error) {
      state.anular.data = [];
      state.anular.status = {
        error,
      };
    },
    voidParadaSuccess(state, response) {
      state.anular.data = response;
      state.anular.status = {
        canceled: true,
      };
    },
    getEstadosParadaRequest(state) {
      state.estados.status = {
        loading: true,
      };
    },
    getEstadosParadaError(state, error) {
      state.estados.data = [];
      state.estados.status = {
        error,
      };
    },
    getEstadosParadaSuccess(state, response) {
      state.estados.data = response;
      state.estados.status = {
        loaded: true,
      };
    },
    getImagesRequest(state) {
      state.images.status = {
        loading: true,
      };
    },
    getImagesError(state, error) {
      state.images.data = [];
      state.images.status = {
        error,
      };
    },
    getImagesSuccess(state, response) {
      state.images.data = response;
      state.images.status = {
        loaded: true,
      };
    },
    getJustificantePDFByParadaRequest(state) {
      state.justificante.status = {
        loading: true,
      };
    },
    getJustificantePDFByParadaError(state, error) {
      state.justificante.data = [];
      state.justificante.status = {
        error,
      };
    },
    getJustificantePDFByParadaSuccess(state, response) {
      state.justificante.data = response;
      state.justificante.status = {
        loaded: true,
      };
    },
    createRecogidaRequest(state) {
      state.entityRecogida.status = {
        creating: true,
      };
    },
    createRecogidaError(state, error) {
      state.entityRecogida.data = [];
      state.entityRecogida.status = {
        error,
      };
    },
    createRecogidaSuccess(state, response) {
      state.entityRecogida.data = response;
      state.entityRecogida.status = {
        created: true,
      };
    },
  },
  actions: {
    getParadas({ commit }, request) {
      commit("getParadasRequest");
      paradaService
        .getParadas(request.pagination, request.filters)
        .then((response) => {
          commit("getParadasSuccess", response);
        })
        .catch((error) => {
          commit("getParadasError", error);
        });
    },
    getParadaInfo({ commit }, masivoId) {
      commit("getParadaInfoRequest");
      paradaService
        .getParada(masivoId)
        .then((response) => {
          commit("getParadaInfoSuccess", response);
        })
        .catch((error) => {
          commit("getParadaInfoError", error);
        });
    },
    getEnviosByParada({ commit }, req) {
      commit("getEnviosByParadaRequest");
      paradaService
        .getEnviosByParada(req.id, req.pagination)
        .then((response) => {
          commit("getEnviosByParadaSuccess", response);
        })
        .catch((error) => {
          commit("getEnviosByParadaError", error);
        });
    },
    getManifiestoByParada({ commit }, idParada) {
      commit("getManifiestoByParadaRequest");
      paradaService
        .getManifiestoByParada(idParada)
        .then((response) => {
          commit("getManifiestoByParadaSuccess", response);
        })
        .catch((error) => {
          commit("getManifiestoByParadaError", error);
        });
    },
    getEtiquetaPDFByParada({ commit }, idParada) {
      commit("getEtiquetaPDFByParadaRequest");
      paradaService
        .getEtiquetaPDFByParada(idParada)
        .then((response) => {
          commit("getEtiquetaPDFByParadaSuccess", response);
        })
        .catch((error) => {
          commit("getEtiquetaPDFByParadaError", error);
        });
    },
    sendEmailEtiquetasByParada({ commit }, idParada) {
      commit("sendEmailEtiquetasByParadaRequest");
      paradaService
        .sendEmailEtiquetasByParada(idParada)
        .then((response) => {
          commit("sendEmailEtiquetasByParadaSuccess", response);
        })
        .catch((error) => {
          commit("sendEmailEtiquetasByParadaError", error);
        });
    },
    voidParada({ commit }, idParada) {
      commit("voidParadaRequest");
      paradaService
        .anularParada(idParada)
        .then((response) => {
          commit("voidParadaSuccess", response);
        })
        .catch((error) => {
          commit("voidParadaError", error);
        });
    },
    getEstadosParada({ commit }, idParada) {
      commit("getEstadosParadaRequest");
      paradaService
        .estadosParada(idParada)
        .then((response) => {
          commit("getEstadosParadaSuccess", response);
        })
        .catch((error) => {
          commit("getEstadosParadaError", error);
        });
    },
    getImages({ commit }, id) {
      commit("getImagesRequest");
      paradaService
        .getImages(id)
        .then((response) => {
          commit("getImagesSuccess", response);
        })
        .catch((error) => {
          commit("getImagesError", error);
        });
    },
    getJustificantePDFByParada({ commit }, idParada) {
      commit("getJustificantePDFByParadaRequest");
      paradaService
        .getJustificantePDFByParada(idParada)
        .then((response) => {
          commit("getJustificantePDFByParadaSuccess", response);
        })
        .catch((error) => {
          commit("getJustificantePDFByParadaError", error);
        });
    },
    createRecogida({ commit }, idParada) {
      commit("createRecogidaRequest");
      paradaService
        .createRecogida(idParada)
        .then((response) => {
          commit("createRecogidaSuccess", response);
        })
        .catch((error) => {
          commit("createRecogidaError", error);
        });
    },
  },
};
