//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      texto: "Indique su correo electrónico, si existe como cuenta, le enviaremos un enlace para restablecerla.",
      email: "",
      isActive: true,
      finished: false
    };
  },
  mounted() {
  },
  computed: {
    statusRestore() {
      return this.$store.state.user.enlace.status;
    }
  },
  methods: {
    submit() {
      this.$store.dispatch("user/getEnlaceRecuperacion", { origen: "PF", user: this.email });
    },
    notificacion(titl, msg, typ) {
      this.$message({
        title: titl,
        message: msg,
        type: typ,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  watch: {
    statusRestore(val) {
      if (val.getted) {
        this.isActive = true;
        this.texto = "Solicitud enviada correctamente, revise su correo electrónico para acceder al enlace de cambio de contraseña."
        this.finished = true;
      } else if (val.error) {
        this.isActive = true;
        this.notificacion("Error!", val.error.error, "error");        
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
