var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Notificaciones")]),
      _c(
        "div",
        { staticClass: "notificaciones-card" },
        _vm._l(_vm.data, function (notification, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("NotificationItem", { attrs: { notification: notification } }),
              index != _vm.data.length - 1
                ? _c("el-divider", { staticClass: "mb-1 mt-1" })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }