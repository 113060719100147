import { createAxios } from "./axiosHttpMarconi";
export const partnerService = {
  partnerSolucion,
  getPartnerList,
  getPartnerByCode,
};

function partnerSolucion(idIncidencia, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/partner/incidencia/${idIncidencia}`;
  return axios.post(urlPath, body);
}
function getPartnerList() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/partners`;
  return axios.get(urlPath);
}
function getPartnerByCode(code) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/partner/${code}`;
  return axios.get(urlPath);
}
