//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'l-progress',
  props: {
    striped: Boolean,
    showText: Boolean,
    animated: Boolean,
    height: {
      type: Number,
      default: 4
    },
    type: {
      type: String,
      default: 'default'
    },
    value: {
      type: Number,
      default: 0,
      validator: (value) => {
        return value >= 0 && value <= 100
      }
    },
    values: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedClasses () {
      return [`bg-${this.type}`, {'progress-bar-striped': this.striped}, {'progress-bar-animated': this.animated}]
    }
  },
  methods: {
    progressClasses (progress) {
      return [`bg-${progress.type}`, {'progress-bar-striped': progress.striped}, {'progress-bar-animated': progress.animated}]
    }
  }
}
