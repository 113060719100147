import { tarifaService } from "@/_services";

export const tarifa = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    allTarifas: {
      data: [],
      status: {},
    },
    tarifasAsExcel: {
      data: [],
      status: {},
    },
    tarifaFranquicia: {
      data: [],
      status: {},
    },
    tarifa: {
      data: {},
      status: {},
    },
    incremento: {
      data: {},
      status: {},
    },
    finalizarTarifa: {
      data: [],
      status: {},
    },
    eliminarTarifas: {
      data: [],
      status: {},
    },
    editarNombre: {
      data: {},
      status: {},
    },
    tarifasMaestros: {
      data: {},
      status: {},
    },
    tarifaMaestro: {
      data: {},
      status: {},
    },
  },
  mutations: {
    eliminarTarifasRequest(state) {
      state.eliminarTarifas.status = {
        loading: true,
      };
    },
    eliminarTarifasSuccess(state, direccion) {
      state.eliminarTarifas.data = direccion;
      state.eliminarTarifas.status = {
        loaded: true,
      };
    },
    eliminarTarifasError(state, error) {
      state.eliminarTarifas.data = [];
      state.eliminarTarifas.status = {
        error,
      };
    },
    getTarifasByFranquiciaRequest(state) {
      state.tarifaFranquicia.status = {
        loading: true,
      };
    },
    cancelTarifasByFranquiciaRequest(state, error) {
      state.tarifaFranquicia.data = [];
      state.tarifaFranquicia.status = {
        error,
      };
    },
    getTarifasByFranquiciaSuccess(state, tarifas) {
      state.tarifaFranquicia.data = tarifas;
      state.tarifaFranquicia.status = {
        loaded: true,
      };
    },
    tarifasExcelRequest(state) {
      state.tarifasAsExcel.status = {
        loading: true,
      };
    },
    tarifasExcelSuccess(state, pdf) {
      state.tarifasAsExcel.data = pdf;
      state.tarifasAsExcel.status = {
        loaded: true,
      };
    },
    tarifasExcelError(state, error) {
      state.tarifasAsExcel.data = null;
      state.tarifasAsExcel.status = {
        error,
      };
    },
    getTarifasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelTarifasRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getTarifasSuccess(state, tarifas) {
      state.list.data = tarifas;
      state.list.status = {
        loaded: true,
      };
    },
    getAllTarifasRequest(state) {
      state.allTarifas.status = {
        loading: true,
      };
    },
    cancelAllTarifasRequest(state, error) {
      state.allTarifas.data = [];
      state.allTarifas.status = {
        error,
      };
    },
    getAllTarifasSuccess(state, tarifas) {
      state.allTarifas.data = tarifas;
      state.allTarifas.status = {
        loaded: true,
      };
    },
    getTarifasByIdRequest(state) {
      state.tarifa.status = {
        loading: true,
      };
    },
    getTarifasByIdError(state, error) {
      state.tarifa.data = {};
      state.tarifa.status = {
        error,
      };
    },
    getTarifasByIdSuccess(state, tarifa) {
      state.tarifa.data = tarifa;
      state.tarifa.status = {
        loaded: true,
      };
    },
    nuevoIncrementoRequest(state) {
      state.incremento.status = {
        creating: true,
      };
    },
    nuevoIncrementoError(state, error) {
      state.incremento.data = {};
      state.incremento.status = {
        error,
      };
    },
    nuevoIncrementoSuccess(state, response) {
      state.incremento.data = response;
      state.incremento.status = {
        created: true,
      };
    },
    tarifaFinalizarRequest(state) {
      state.finalizarTarifa.status = {
        editing: true,
      };
    },
    tarifaFinalizarError(state, error) {
      state.finalizarTarifa.data = {};
      state.finalizarTarifa.status = {
        error,
      };
    },
    tarifaFinalizarSuccess(state, response) {
      state.finalizarTarifa.data = response;
      state.finalizarTarifa.status = {
        edited: true,
      };
    },
    editNombreTarifaRequest(state) {
      state.editarNombre.status = {
        editing: true,
      };
    },
    editNombreTarifaError(state, error) {
      state.editarNombre.data = {};
      state.editarNombre.status = {
        error,
      };
    },
    editNombreTarifaSuccess(state, response) {
      state.editarNombre.data = response;
      state.editarNombre.status = {
        edited: true,
      };
    },
    getTarifasMaestrosRequest(state) {
      state.tarifasMaestros.status = {
        loading: true,
      };
    },
    getTarifasMaestrosError(state, error) {
      state.tarifasMaestros.data = {};
      state.tarifasMaestros.status = {
        error,
      };
    },
    getTarifasMaestrosSuccess(state, response) {
      state.tarifasMaestros.data = response;
      state.tarifasMaestros.status = {
        loaded: true,
      };
    },
    getTarifaMaestroByIdRequest(state) {
      state.tarifaMaestro.status = {
        loading: true,
      };
    },
    getTarifaMaestroByIdError(state, error) {
      state.tarifaMaestro.data = {};
      state.tarifaMaestro.status = {
        error,
      };
    },
    getTarifaMaestroByIdSuccess(state, response) {
      state.tarifaMaestro.data = response;
      state.tarifaMaestro.status = {
        loaded: true,
      };
    },
  },
  actions: {
    eliminarTarifas({ commit }, body) {
      commit("eliminarTarifasRequest");
      tarifaService
        .eliminarTarifas(body)
        .then((tarifas) => {
          commit("eliminarTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("eliminarTarifasError", error);
        });
    },
    finalizarTarifas({ commit }, body) {
      commit("tarifaFinalizarRequest");
      tarifaService
        .finalizarTarifas(body)
        .then((response) => {
          commit("tarifaFinalizarSuccess", response);
        })
        .catch((error) => {
          commit("tarifaFinalizarError", error);
        });
    },
    getTarifas({ commit }) {
      commit("getTarifasRequest");
      tarifaService
        .getTarifas()
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasRequest", error);
        });
    },
    getTarifasByFranquiciaId({ commit }, codigo) {
      commit("getTarifasByFranquiciaRequest");
      tarifaService
        .getTarifasByFranquiciaId(codigo)
        .then((tarifas) => {
          commit("getTarifasByFranquiciaSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasByFranquiciaRequest", error);
        });
    },
    getAllTarifas({ commit }, req) {
      commit("getAllTarifasRequest");
      tarifaService
        .getAllTarifas(req.pagination, req.filters)
        .then((tarifas) => {
          commit("getAllTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelAllTarifasRequest", error);
        });
    },
    getTarifasByFranquicia({ commit }) {
      commit("getTarifasRequest");
      tarifaService
        .getTarifasByFranquicia()
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasRequest", error);
        });
    },
    getAllTarifasAsExcel({ commit }, request) {
      commit("tarifasExcelRequest");
      tarifaService
        .getAllTarifasAsExcel(request)
        .then((excel) => {
          commit("tarifasExcelSuccess", excel);
        })
        .catch((error) => {
          commit("tarifasExcelError", error);
        });
    },
    getTarifasById({ commit }, id) {
      commit("getTarifasByIdRequest");
      tarifaService
        .getTarifasById(id)
        .then((tarifas) => {
          commit("getTarifasByIdSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifasByIdError", error);
        });
    },
    nuevoIncremento({ commit }, req) {
      commit("nuevoIncrementoRequest");
      tarifaService
        .nuevoIncremento(req)
        .then((response) => {
          commit("nuevoIncrementoSuccess", response);
        })
        .catch((error) => {
          commit("nuevoIncrementoError", error);
        });
    },
    getTarifasMaestros({ commit }, req) {
      commit("getTarifasMaestrosRequest");
      tarifaService
        .getTarifasMaestros(req.pagination, req.filters)
        .then((response) => {
          commit("getTarifasMaestrosSuccess", response);
        })
        .catch((error) => {
          commit("getTarifasMaestrosError", error);
        });
    },
    editNombreTarifa({ commit }, req) {
      commit("editNombreTarifaRequest");
      tarifaService
        .editNombreTarifa(req.idTarifa, req.body)
        .then((response) => {
          commit("editNombreTarifaSuccess", response);
        })
        .catch((error) => {
          commit("editNombreTarifaError", error);
        });
    },
    getTarifaMaestroById({ commit }, id) {
      commit("getTarifaMaestroByIdRequest");
      tarifaService
        .getTarifaMaestroById(id)
        .then((response) => {
          commit("getTarifaMaestroByIdSuccess", response);
        })
        .catch((error) => {
          commit("getTarifaMaestroByIdError", error);
        });
    },
  },
};
