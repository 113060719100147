var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hk-direccion" },
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "Seleccion una direccion" },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.direcciones, function (item) {
          return _c(
            "el-option",
            { key: item._id, attrs: { label: item.label, value: item._id } },
            [
              _c("span", { staticStyle: { float: "left" } }, [
                _vm._v(_vm._s(item.label)),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    float: "right",
                    color: "#8492a6",
                    "font-size": "13px",
                  },
                },
                [_vm._v(_vm._s(item.value))]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }