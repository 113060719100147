var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "div",
      {
        staticClass: "dropdown-toggle",
        attrs: {
          id: _vm.idToggle,
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
          "data-bs-auto-close": "outside",
        },
      },
      [
        _c(
          "el-input",
          {
            staticClass: "searchInput",
            attrs: {
              size: _vm.size,
              placeholder: _vm.placeholder,
              id: _vm.idSearchAutocomplete,
            },
            on: { input: _vm.textChanged },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          },
          [
            _c("i", {
              staticClass: "el-input__icon",
              class: _vm.iconText,
              attrs: { slot: "suffix", id: _vm.idIcon },
              on: {
                click: function ($event) {
                  return _vm.clickIcono()
                },
              },
              slot: "suffix",
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dropdown-menu mt-1", attrs: { id: _vm.idDropdownMenu } },
      [
        _c("div", [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.load,
                  expression: "load",
                },
              ],
              staticClass: "infinite-list",
              staticStyle: { overflow: "auto" },
            },
            [
              _vm._t("default", function () {
                return [
                  _c(
                    "p",
                    { staticClass: "infinite-list-item empty text-center" },
                    [_vm._v("Sin datos")]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }