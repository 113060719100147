import Vue from "vue";
import Vuex from "vuex";

import { user } from "./user.module";
import { usuario } from "./usuario.module";
import { franquicia } from "./franquicia.module";
import { pais } from "./pais.module";
import { codigoPostal } from "./codigoPostal.module";
import { servicios } from "./servicios.module";
import { envio } from "./envio.module";
import { envios } from "./envios.module";
import { masivo } from "./masivo.module";
import { cliente } from "./cliente.module";
import { tarifa } from "./tarifa.module";
import { valoracion } from "./valoracion.module";
import { libretaContacto } from "./libretaContacto.module";
import { albaran } from "./albaran.module";
import { formasPago } from "./formasPago.module";
import { vencimientos } from "./vencimientos.module";
import { factura } from "./factura.module";
import { efecto } from "./efecto.module";
import { remesa } from "./remesa.module";
import { incidencia } from "./incidencia.module";
import { franquiciadora } from "./franquiciadora.module";
import { parada } from "./parada.module";
import { estado } from "./estado.module";
import { vistaOperativa } from ".//vistaOperativa.module";
import { notificacion } from "./notificacion.module";
import { partner } from "./partner.module";
import { rma } from "./rma.module";
import { suplemento } from "./suplemento.module";
import { puntoRed } from "./puntoRed.module";
import { exportacionContable } from "./exportacionContable.module";
import { fecha } from "./fecha.module";
import { tarifaBase } from "./tarifaBase.module";
import { repartidor } from "./repartidor.module";
import { recogidaProgramada } from "./recorgidaProgramada.module";
import { enviosAgrupados } from "./enviosAgrupados.module";
import { documentacion } from "./documentacion.module";
import { impuestos } from "./impuestos.module";
import { roles } from "./roles.module";
import { dashboard } from "./dashboard.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    usuario,
    franquicia,
    pais,
    codigoPostal,
    servicios,
    envio,
    envios,
    masivo,
    cliente,
    tarifa,
    valoracion,
    libretaContacto,
    albaran,
    formasPago,
    vencimientos,
    factura,
    efecto,
    remesa,
    incidencia,
    franquiciadora,
    parada,
    estado,
    vistaOperativa,
    notificacion,
    partner,
    rma,
    suplemento,
    puntoRed,
    exportacionContable,
    fecha,
    tarifaBase,
    repartidor,
    recogidaProgramada,
    enviosAgrupados,
    documentacion,
    impuestos,
    roles,
    dashboard,
  },
});
