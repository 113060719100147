import { partnerService } from "@/_services";

export const partner = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    solucion: {
      data: {},
      status: {},
    },
    listPartners: {
      data: {},
      status: {},
    },
  },
  mutations: {
    partnerSolucionRequest(state) {
      state.solucion.status = {
        solving: true,
      };
    },
    partnerSolucionError(state, error) {
      state.solucion.data = null;
      state.solucion.status = {
        error,
      };
    },
    partnerSolucionSuccess(state, res) {
      state.solucion.data = res;
      state.solucion.status = {
        solved: true,
      };
    },
    partnerListRequest(state) {
      state.listPartners.status = {
        loading: true,
      };
    },
    partnerListError(state, error) {
      state.listPartners.data = null;
      state.listPartners.status = {
        error,
      };
    },
    partnerListSuccess(state, res) {
      state.listPartners.data = res;
      state.listPartners.status = {
        loaded: true,
      };
    },
    getPartnerByCodeRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getPartnerByCodeError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getPartnerByCodeSuccess(state, res) {
      state.entity.data = res;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    partnerSolucion({ commit }, req) {
      commit("partnerSolucionRequest");
      partnerService
        .partnerSolucion(req.id, req.body)
        .then((res) => {
          commit("partnerSolucionSuccess", res);
        })
        .catch((error) => {
          commit("partnerSolucionError", error);
        });
    },
    getListPartners({ commit }) {
      commit("partnerListRequest");
      partnerService
        .getPartnerList()
        .then((res) => {
          commit("partnerListSuccess", res);
        })
        .catch((error) => {
          commit("partnerListError", error);
        });
    },
    getPartnerByCode({ commit }, code) {
      commit("getPartnerByCodeRequest");
      partnerService
        .getPartnerByCode(code)
        .then((res) => {
          commit("getPartnerByCodeSuccess", res);
        })
        .catch((error) => {
          commit("getPartnerByCodeError", error);
        });
    },
  },
};
