var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 m-0 p-0 toolbar" }, [
    _c(
      "ul",
      { staticClass: "nav nav-pills" },
      _vm._l(_vm.acciones, function (item, key) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadings.includes(item.evento),
                expression: "loadings.includes(item.evento)",
              },
            ],
            key: key,
            staticClass: "nav-item",
          },
          [
            !item.disabled
              ? _c("div", [
                  item.group
                    ? _c("div", { staticClass: "btn-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn p-2 ps-3 pe-1",
                            class: { "btn-primary": item.activo },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.launchEvents(item.default)
                              },
                            },
                          },
                          [
                            item.default.icon != ""
                              ? _c("i", {
                                  staticClass: "fa",
                                  class: item.default.icon,
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item.default.label) + " "),
                          ]
                        ),
                        _c("button", {
                          staticClass:
                            "btn dropdown-toggle dropdown-toggle-split p-2",
                          class: { "btn-primary": item.activo },
                          attrs: {
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false",
                          },
                        }),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { id: "toggleButton" },
                          },
                          _vm._l(item.group, function (item, key) {
                            return _c("li", { key: key }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.launchEvents(item)
                                    },
                                  },
                                },
                                [
                                  item.icon != ""
                                    ? _c("i", {
                                        staticClass: "gray me-2 fa",
                                        class: item.icon,
                                      })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(item.label) + " "),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ])
                    : item.items
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle",
                            class: { active: item.activo },
                            attrs: {
                              "data-bs-toggle": "dropdown",
                              href: "#",
                              role: "button",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            item.icon != ""
                              ? _c("i", { staticClass: "fa", class: item.icon })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item.label) + " "),
                          ]
                        ),
                        _c(
                          "ul",
                          { staticClass: "dropdown-menu" },
                          _vm._l(item.items, function (subItem, key) {
                            return _c("li", { key: key }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item gray",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.launchEvents(subItem)
                                    },
                                  },
                                },
                                [
                                  subItem.icon != ""
                                    ? _c("i", {
                                        staticClass: "gray me-2 fa",
                                        class: subItem.icon,
                                      })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(subItem.label) + " "),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ])
                    : _c(
                        "a",
                        {
                          staticClass: "nav-link text-sm",
                          class: { active: item.activo },
                          on: {
                            click: function ($event) {
                              return _vm.launchEvents(item)
                            },
                          },
                        },
                        [
                          item.icon != ""
                            ? _c("i", { staticClass: "fa", class: item.icon })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.label) + " "),
                        ]
                      ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }