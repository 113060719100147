import { masivoService } from "@/_services";

export const masivo = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    masivoAnulado: {
      data: {},
      status: {},
    },
    masivoEtiquetas: {
      data: null,
      status: {}
    },
    enviosMasivos:{
      data: [],
      status: {},
    },
    masivoId: {
      data: [],
      status: {},
    },
    download: {
      data: {},
      status: {}
    }
  },
  mutations: {
    getMasivoDownloadRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    getMasivoDownloadError(state, error) {
      state.download.data = {};
      state.download.status = { 
        error,
      };
    },
    getMasivoDownloadSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },
    getMasivoIdRequest(state) {
      state.masivoId.status = {
        loading: true,
      };
    },
    cancelMasivoIdRequest(state, error) {
      state.masivoId.data = [];
      state.masivoId.status = {
        error,
      };
    },
    getMasivoIdSuccess(state, cp) {
      state.masivoId.data = cp;
      state.masivoId.status = {
        loaded: true,
      };
    },

    getMasivoRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelMasivoRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getMasivoSuccess(state, cp) {
      state.list.data = cp;
      state.list.status = {
        loaded: true,
      };
    },
    getMasivoEtiquetaRequest(state) {
      state.masivoEtiquetas.status = {
        printing: true,
      };
    },
    getMasivoEtiquetasError(state, error) {
      state.masivoEtiquetas.data = null;
      state.masivoEtiquetas.status = {
        error,
      };
    },
    getMasivoEtiquetasSuccess(state, blob) {
      state.masivoEtiquetas.data = blob;
      state.masivoEtiquetas.status = {
        printed: true,
      };
    },
    anularMasivoRequest(state) {
      state.masivoAnulado.status = {
        anulando: true,
      };
    },
    anularMasivoError(state, error) {
      state.masivoAnulado.data = {};
      state.masivoAnulado.status = {
        error,
      };
    },
    anularMasivoSuccess(state, res) {
      state.masivoAnulado.data = res;
      state.masivoAnulado.status = {
        anulado: true,
      };
    },

    getEnviosMasivosRequest(state) {
      state.enviosMasivos.status = {
        loading: true,
      };
    },
    getEnviosMasivosError(state, error) {
      state.enviosMasivos.data = [];
      state.enviosMasivos.status = {
        error,
      };
    },
    getEnviosMasivosSuccess(state, cp) {
      state.enviosMasivos.data = cp;
      state.enviosMasivos.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getMasivos({ commit }, req) {
      commit("getMasivoRequest");
      masivoService
        .getMasivos(req.pagination, req.filters)
        .then((mas) => {
          commit("getMasivoSuccess", mas);
        })
        .catch((error) => {
          commit("cancelMasivoRequest", error);
        });
    },
    masivoEtiquetas({ commit }, req) {
      commit("getMasivoEtiquetaRequest");
      masivoService
        .getMasivoEtiquetas(req)
        .then((etiquetas) => {
          commit("getMasivoEtiquetasSuccess", etiquetas);
        })
        .catch((error) => {
          commit("getMasivoEtiquetasError", error);
        });
    },
    anularMasivo({ commit }, req) {
      commit("anularMasivoRequest");
      masivoService
        .anularMasivo(req)
        .then((masivo) => {
          commit("anularMasivoSuccess", masivo);
        })
        .catch((error) => {
          commit("anularMasivoError", error);
        });
    },
    getMasivo({ commit }, req) {
      commit("getMasivoIdRequest");
      masivoService
        .getMasivo(req)
        .then((masivo) => {
          commit("getMasivoIdSuccess", masivo);
        })
        .catch((error) => {
          commit("cancelMasivoIdRequest", error);
        });
    },
    getEnviosMasivos({ commit }, idMasivo) {
      commit('getEnviosMasivosRequest');
      masivoService
        .getEnviosMasivos(idMasivo)
        .then((response) => {
          commit('getEnviosMasivosSuccess', response);
        })
        .catch((error) => {
          commit('getEnviosMasivosError', error);
        });
    },
    getMasivoDownload({ commit }, idMasivo) {
      commit('getMasivoDownloadRequest');
      masivoService
        .getMasivoDownload(idMasivo)
        .then((response) => {
          commit('getMasivoDownloadSuccess', response);
        })
        .catch((error) => {
          commit('getMasivoDownloadError', error);
        });
    },
  },
};
