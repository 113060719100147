import { repartidorService } from "@/_services";
export const repartidor = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getRepartidoresRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getRepartidoresError(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getRepartidoresSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getRepartidores({ commit }, req) {
      commit("getRepartidoresRequest");
      repartidorService
        .getRepartidores(req.pagination, req.filters)
        .then((env) => {
          commit("getRepartidoresSuccess", env);
        })
        .catch((error) => {
          commit("getRepartidoresError", error);
        });
    },
  },
};
