//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  name: "Actions",
  props: {
    acciones: {
      required: true,
      default: () => [],
    },
    loadings: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    launchEvents(accion) {
      this.$emit("triggerEvent", accion.evento);
    },
  },
  watch: {},
};
