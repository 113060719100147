var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert open",
      class: [
        { "alert-with-icon": _vm.icon },
        _vm.verticalAlign,
        _vm.horizontalAlign,
        _vm.alertType,
      ],
      style: _vm.customPosition,
      attrs: {
        "data-notify": "container",
        role: "alert",
        "data-notify-position": "top-center",
      },
      on: { click: _vm.tryClose },
    },
    [
      _vm.showClose
        ? _c(
            "button",
            {
              staticClass: "close col-xs-1",
              attrs: {
                type: "button",
                "aria-hidden": "true",
                "data-notify": "dismiss",
              },
              on: { click: _vm.close },
            },
            [_c("i", { staticClass: "nc-icon nc-simple-remove" })]
          )
        : _vm._e(),
      _vm.icon
        ? _c("span", {
            class: ["alert-icon", _vm.icon],
            attrs: { "data-notify": "icon" },
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { "data-notify": "message" } },
        [
          _vm.title
            ? _c("span", { staticClass: "title" }, [
                _c("b", [_vm._v(_vm._s(_vm.title)), _c("br")]),
              ])
            : _vm._e(),
          _vm.message
            ? _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })
            : _vm._e(),
          !_vm.message && _vm.component
            ? _c("content-render", { attrs: { component: _vm.component } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }