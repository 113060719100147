import { createAxios } from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

function getEnviosAgrupados(pagination, filters) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envios-agrupados`;

  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }

  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&cliente_id=${filters.idCliente}`;
  }

  return axios.get(urlPath);
}

function imprimirEtiquetas(id) {
  const axios = createAxios();
  const urlPath = `envios-agrupados/${id}/etiquetas`;
  return axios.get(urlPath, { responseType: "blob" });
}

function finalizar(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envios-agrupados/finalizar`;
  return axios.post(urlPath, body);
}

export const enviosAgrupadosService = {
  getEnviosAgrupados,
  imprimirEtiquetas,
  finalizar,
};
