var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("l-button", { attrs: { type: "default", wide: "" } }, [
                  _vm._v("Default"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { type: "primary", wide: "" } }, [
                  _vm._v("Primary"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { type: "info", wide: "" } }, [
                  _vm._v("Info"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { type: "success", wide: "" } }, [
                  _vm._v("Success"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { type: "warning", wide: "" } }, [
                  _vm._v("Warning"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { type: "danger", wide: "" } }, [
                  _vm._v("Danger"),
                ]),
                _vm._v("  "),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("l-button", { attrs: { wide: "" } }, [_vm._v("Default")]),
                _vm._v("  "),
                _c("l-button", { attrs: { wide: "", outline: "" } }, [
                  _vm._v("Outline"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { wide: "", round: "" } }, [
                  _vm._v("Fill + Round"),
                ]),
                _vm._v("  "),
                _c(
                  "l-button",
                  { attrs: { wide: "", outline: "", round: "" } },
                  [_vm._v("Outline + Round")]
                ),
                _vm._v("  "),
                _c("l-button", { attrs: { wide: "", simple: "", link: "" } }, [
                  _vm._v("Simple"),
                ]),
                _vm._v("  "),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "l-button",
                  { attrs: { wide: "", type: "success", outline: "" } },
                  [
                    _c("span", { staticClass: "btn-label" }, [
                      _c("i", { staticClass: "fa fa-check" }),
                    ]),
                    _vm._v(" Success "),
                  ]
                ),
                _vm._v("  "),
                _c(
                  "l-button",
                  { attrs: { type: "danger", wide: "", outline: "" } },
                  [
                    _c("span", { staticClass: "btn-label" }, [
                      _c("i", { staticClass: "fa fa-times" }),
                    ]),
                    _vm._v(" Danger "),
                  ]
                ),
                _vm._v("  "),
                _c(
                  "l-button",
                  { attrs: { type: "info", outline: "", wide: "" } },
                  [
                    _c("span", { staticClass: "btn-label" }, [
                      _c("i", { staticClass: "fa fa-exclamation" }),
                    ]),
                    _vm._v(" Info "),
                  ]
                ),
                _vm._v("  "),
                _c(
                  "l-button",
                  { attrs: { type: "warning", wide: "", outline: "" } },
                  [
                    _c("span", { staticClass: "btn-label" }, [
                      _c("i", { staticClass: "fa fa-warning" }),
                    ]),
                    _vm._v(" Warning "),
                  ]
                ),
                _vm._v("  "),
                _c(
                  "l-button",
                  { attrs: { type: "default", wide: "", outline: "" } },
                  [
                    _c("span", { staticClass: "btn-label" }, [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                    ]),
                    _vm._v(" Left "),
                  ]
                ),
                _vm._v("  "),
                _c(
                  "l-button",
                  {
                    attrs: {
                      type: "default",
                      wide: "",
                      default: "",
                      outline: "",
                    },
                  },
                  [
                    _vm._v(" Right "),
                    _c("span", { staticClass: "btn-label btn-label-right" }, [
                      _c("i", { staticClass: "fa fa-arrow-right" }),
                    ]),
                  ]
                ),
                _vm._v("  "),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("l-button", { attrs: { size: "lg" } }, [_vm._v("Large")]),
                _vm._v("  "),
                _c("l-button", [_vm._v("Default")]),
                _vm._v("  "),
                _c("l-button", { attrs: { size: "sm" } }, [_vm._v("Small")]),
                _vm._v("  "),
                _c("br"),
                _c("l-button", { attrs: { round: "", size: "lg" } }, [
                  _vm._v("Large"),
                ]),
                _vm._v("  "),
                _c("l-button", { attrs: { round: "" } }, [_vm._v("Default")]),
                _vm._v("  "),
                _c("l-button", { attrs: { round: "", size: "sm" } }, [
                  _vm._v("Small"),
                ]),
                _vm._v("  "),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._m(4),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._m(5),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("l-pagination", {
                  attrs: { "page-count": 10 },
                  model: {
                    value: _vm.defaultPagination,
                    callback: function ($$v) {
                      _vm.defaultPagination = $$v
                    },
                    expression: "defaultPagination",
                  },
                }),
                _c("l-pagination", {
                  staticClass: "pagination-no-border",
                  attrs: { "page-count": 10 },
                  model: {
                    value: _vm.infoPagination,
                    callback: function ($$v) {
                      _vm.infoPagination = $$v
                    },
                    expression: "infoPagination",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._m(6),
        _vm._m(7),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Colors")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Styles")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Buttons with Label")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Sizes")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("h4", { staticClass: "card-title" }, [_vm._v("Button Group")]),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("Left")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("Middle")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("Right")]
          ),
        ]),
        _vm._v("  "),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("1")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("2")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("3")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("4")]
          ),
        ]),
        _vm._v("  "),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("5")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("6")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("7")]
          ),
        ]),
        _vm._v("  "),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default btn-outline",
              attrs: { type: "button" },
            },
            [_vm._v("8")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Pagination")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Social buttons")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-4 social-buttons-demo" }, [
          _c("h4", { staticClass: "title" }, [
            _c("small", [_vm._v(" Filled ")]),
          ]),
          _c("button", { staticClass: "btn btn-social  btn-twitter" }, [
            _c("i", { staticClass: "fa fa-twitter" }),
            _vm._v(" Connect with Twitter "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-facebook" }, [
            _c("i", { staticClass: "fa fa-facebook-square" }),
            _vm._v(" Share · 2.2k "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-google" }, [
            _c("i", { staticClass: "fa fa-google-plus-square" }),
            _vm._v(" Share on Google+ "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-linkedin" }, [
            _c("i", { staticClass: "fa fa-linkedin-square" }),
            _vm._v(" Connect with Linkedin "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-pinterest" }, [
            _c("i", { staticClass: "fa fa-pinterest" }),
            _vm._v(" Pint it · 212 "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-youtube" }, [
            _c("i", { staticClass: "fa fa-youtube-play" }),
            _vm._v(" View on Youtube "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-tumblr" }, [
            _c("i", { staticClass: "fa fa-tumblr-square" }),
            _vm._v(" Repost "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-github" }, [
            _c("i", { staticClass: "fa fa-github" }),
            _vm._v(" Connect with Github "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-behance" }, [
            _c("i", { staticClass: "fa fa-behance-square" }),
            _vm._v(" Follow us "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-dribbble" }, [
            _c("i", { staticClass: "fa fa-dribbble" }),
            _vm._v(" Find us on Dribbble "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-reddit" }, [
            _c("i", { staticClass: "fa fa-reddit" }),
            _vm._v(" Repost · 232 "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social  btn-stumbleupon" }, [
            _c("i", { staticClass: "fa fa-stumbleupon" }),
            _vm._v(" View on StumbleUpon "),
          ]),
          _c("br"),
        ]),
        _c("div", { staticClass: "col-md-2 social-buttons-demo" }, [
          _c("h4", { staticClass: "title" }, [_c("small", [_vm._v("Light")])]),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-twitter btn-outline" },
            [_c("i", { staticClass: "fa fa-twitter" })]
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-social btn-round btn-facebook btn-outline",
            },
            [_c("i", { staticClass: "fa fa-facebook" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-google btn-outline" },
            [_c("i", { staticClass: "fa fa-google-plus" })]
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-social btn-round btn-linkedin btn-outline",
            },
            [_c("i", { staticClass: "fa fa-linkedin" })]
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-social btn-round btn-pinterest btn-outline",
            },
            [_c("i", { staticClass: "fa fa-pinterest" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-youtube btn-outline" },
            [_c("i", { staticClass: "fa fa-youtube" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-tumblr btn-outline" },
            [_c("i", { staticClass: "fa fa-tumblr" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-github btn-outline" },
            [_c("i", { staticClass: "fa fa-github" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-behance btn-outline" },
            [_c("i", { staticClass: "fa fa-behance" })]
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn btn-social btn-round btn-dribbble btn-outline",
            },
            [_c("i", { staticClass: "fa fa-dribbble" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-round btn-reddit btn-outline" },
            [_c("i", { staticClass: "fa fa-reddit" })]
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass:
                "btn btn-social btn-round btn-stumbleupon btn-outline",
            },
            [_c("i", { staticClass: "fa fa-stumbleupon" })]
          ),
          _c("br"),
        ]),
        _c("div", { staticClass: "col-md-2 social-buttons-demo" }, [
          _c("h4", { staticClass: "title" }, [_c("small", [_vm._v("Simple")])]),
          _c("button", { staticClass: "btn btn-social btn-link btn-twitter" }, [
            _c("i", { staticClass: "fa fa-twitter" }),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-facebook" },
            [_c("i", { staticClass: "fa fa-facebook-square" })]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-google" }, [
            _c("i", { staticClass: "fa fa-google-plus-square" }),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-linkedin" },
            [_c("i", { staticClass: "fa fa-linkedin-square" })]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-pinterest" },
            [_c("i", { staticClass: "fa fa-pinterest" })]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-youtube" }, [
            _c("i", { staticClass: "fa fa-youtube" }),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-tumblr" }, [
            _c("i", { staticClass: "fa fa-tumblr-square" }),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-github" }, [
            _c("i", { staticClass: "fa fa-github" }),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-behance" }, [
            _c("i", { staticClass: "fa fa-behance" }),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-dribbble" },
            [_c("i", { staticClass: "fa fa-dribbble" })]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-reddit" }, [
            _c("i", { staticClass: "fa fa-reddit" }),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-stumbleupon" },
            [_c("i", { staticClass: "fa fa-stumbleupon" })]
          ),
          _c("br"),
        ]),
        _c("div", { staticClass: "col-md-4 social-buttons-demo" }, [
          _c("h4", { staticClass: "title" }, [
            _c("small", [_vm._v("With Text")]),
          ]),
          _c("button", { staticClass: "btn btn-social btn-link btn-twitter" }, [
            _c("i", { staticClass: "fa fa-twitter" }),
            _vm._v(" Connect with Twitter "),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-facebook" },
            [
              _c("i", { staticClass: "fa fa-facebook-square" }),
              _vm._v(" Share · 2.2k "),
            ]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-google" }, [
            _c("i", { staticClass: "fa fa-google-plus-square" }),
            _vm._v(" Share on Google+ "),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-linkedin" },
            [
              _c("i", { staticClass: "fa fa-linkedin-square" }),
              _vm._v(" Connect with Linkedin "),
            ]
          ),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-pinterest" },
            [
              _c("i", { staticClass: "fa fa-pinterest" }),
              _vm._v(" Pint it · 212 "),
            ]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-youtube" }, [
            _c("i", { staticClass: "fa fa-youtube-play" }),
            _vm._v(" View on Youtube "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-tumblr" }, [
            _c("i", { staticClass: "fa fa-tumblr-square" }),
            _vm._v(" Repost "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-github" }, [
            _c("i", { staticClass: "fa fa-github" }),
            _vm._v(" Connect with Github "),
          ]),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-behance" }, [
            _c("i", { staticClass: "fa fa-behance-square" }),
            _vm._v(" Follow us "),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-dribbble" },
            [
              _c("i", { staticClass: "fa fa-dribbble" }),
              _vm._v(" Find us on Dribbble "),
            ]
          ),
          _c("br"),
          _c("button", { staticClass: "btn btn-social btn-link btn-reddit" }, [
            _c("i", { staticClass: "fa fa-reddit" }),
            _vm._v(" Repost · 232 "),
          ]),
          _c("br"),
          _c(
            "button",
            { staticClass: "btn btn-social btn-link btn-stumbleupon" },
            [
              _c("i", { staticClass: "fa fa-stumbleupon" }),
              _vm._v(" View on StumbleUpon "),
            ]
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }