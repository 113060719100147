import { createAxios } from "./axiosHttpMarconi";
export const recogidaProgramadaService = {
  createRecogidaProgramada,
  getRecogidasProgramadas,
  eliminarRecogidaProgramada,
  scheduleRecogidaProgramada,
  getRecogidaProgramadaById,
};

function getRecogidasProgramadas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/recogidas-programadas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.resumen) {
    urlPath += `&resumen=${filters.resumen}`;
  }
  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.courier) {
    urlPath += `&courier=${filters.courier}`;
  }
  return axios.get(urlPath);
}

function eliminarRecogidaProgramada(idRecogida) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `/franquicia/${idFranquicia._id}/recogidas-programadas/${idRecogida}`;
  return axios.delete(urlPath);
}

function scheduleRecogidaProgramada(idRecogida, body) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `/franquicia/${idFranquicia._id}/recogidas-programadas/${idRecogida}/scheduler`;
  return axios.put(urlPath, body);
}

function getRecogidaProgramadaById(idRecogida) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `/franquicia/${idFranquicia._id}/recogidas-programadas/${idRecogida}`;
  return axios.get(urlPath);
}

function createRecogidaProgramada(body) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `/franquicia/${idFranquicia._id}/recogidas-programadas`;
  return axios.post(urlPath, body);
}
