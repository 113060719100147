var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "estado",
      class: [
        ((_obj = {}),
        (_obj["estado-" + _vm.label.toLowerCase()] = _vm.type),
        _obj),
      ],
    },
    [_vm._v(" " + _vm._s(_vm.label.replace("_", " ").toUpperCase()) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }