//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "@/components/ModalHotakauth.vue";
export default {
  components: { Modal },
  data() {
    const validate = (rulesPassword, value, callback) => {
      if (value !== "") {
        if (value !== this.usuario.newPassword) {
          callback(new Error("Las contraseñas no coinciden"));
        } else {
          callback();
        }
      }
    };
    return {
      usuario: {
        user: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      nombreUsuario: null,
      rulesPassword: {
        password: [
          {
            required: true,
            message: "Indique la contraseña actual",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "Indique la nueva contraseña",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
            message:
              "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos",
          },
          { validator: validate, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Confirme la contraseña",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
            message:
              "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos",
          },
          { validator: validate, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.nombreUsuario = JSON.parse(localStorage.getItem("user"));
  },
  computed: {
    changePassword() {
      return this.$store.state.user.editPass.status;
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    editedpassword() {
      this.$refs["changePassword"].validate((valid) => {
        if (valid) {
          this.usuario.user = this.nombreUsuario.user;
          this.usuario.userId = this.nombreUsuario.userId;
          this.$store.dispatch("user/changePassword", this.usuario);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    changePassword() {
      if (this.$store.state.user.editPass.status.edited) {
        this.notificacion(
          "Correcto!",
          "Tu contraseña ha sido cambiada correctamente!",
          "success"
        );
        setTimeout(() => {
          localStorage.removeItem("user");
          localStorage.removeItem("agrupacionClienteSelected");
          localStorage.removeItem("empleado");
          this.$router.push({ name: "Login" });
        }, 500);
      } else if (this.$store.state.user.editPass.status.error) {
        this.notificacion(
          "Error!",
          this.$store.state.user.editPass.status.error.error,
          "danger"
        );
      }
    },
  },
};
