import { rmaService } from '@/_services';

export const rma = {
  namespaced: true,
  state: {
    list: {
      data: {},
      status: {}
    },
    cadRev: {
      data: {},
      status: {}
    },
    send: {
      data: {},
      status: {}
    },
    rmaMasivos: {
      data: {},
      status: {}
    },
    download: {
      data: {},
      status: {}
    }
  },
  mutations: {
    getRmaDownloadRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    getRmaDownloadError(state, error) {
      state.download.data = {};
      state.download.status = {
        error,
      };
    },
    getRmaDownloadSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },

    getRmasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getRmasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getRmasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    caducarRevertirRmaRequest(state) {
      state.cadRev.status = {
        loading: true,
      };
    },
    caducarRevertirRmaSuccess(state, env) {
      state.cadRev.data = env;
      state.cadRev.status = {
        loaded: true,
      };
    },
    caducarRevertirRmaError(state, error) {
      state.cadRev.data = null
      state.cadRev.status = {
        error
      }
    },
    sendRmaRequest(state) {
      state.send.status = {
        creating: true,
      };
    },
    sendRmaSuccess(state, response) {
      state.send.data = response;
      state.send.status = {
        created: true,
      };
    },
    sendRmaError(state, error) {
      state.send.data = [];
      state.send.status = {
        error,
      };
    },
    getRmaMasivosRequest(state) {
      state.rmaMasivos.status = {
        loading: true,
      };
    },
    getRmaMasivosError(state, error) {
      state.rmaMasivos.data = [];
      state.rmaMasivos.status = {
        error,
      };
    },
    getRmaMasivosSuccess(state, cp) {
      state.rmaMasivos.data = cp;
      state.rmaMasivos.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getRmaDownload({ commit }, idRma) {
      commit('getRmaDownloadRequest');
      rmaService
        .getRmaDownload(idRma)
        .then((response) => {
          commit('getRmaDownloadSuccess', response);
        })
        .catch((error) => {
          commit('getRmaDownloadError', error);
        });
    },
    getRmaMasivos({ commit }, req) {
      commit('getRmaMasivosRequest');
      rmaService
        .getRmaMasivos(req.pagination, req.filters)
        .then((response) => {
          commit('getRmaMasivosSuccess', response);
        })
        .catch((error) => {
          commit('getRmaMasivosError', error);
        });
    },
    getRmas({ commit }, req) {
      commit('getRmasRequest');
      rmaService.getRmas(req.pagination, req.body)
        .then((response) => {
          commit('getRmasSuccess', response);
        }).catch((error) => {
          commit('getRmasError', error);
        });
    },
    caducarRevertirRma({ commit }, req) {
      commit('caducarRevertirRmaRequest');
      rmaService.caducarRevertirRma(req.idRma, req.body)
        .then((rma) => {
          commit('caducarRevertirRmaSuccess', rma);
        })
        .catch((error) => {
          commit('caducarRevertirRmaError', error);
        });
    },
    sendEnvioRma({ commit }, body) {
      commit('sendRmaRequest');
      rmaService.sendEnvioRma(body)
        .then((response) => {
          commit('sendRmaSuccess', response);
        }).catch((error) => {
          commit('sendRmaError', error);
        });
    },
  },
};