//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  name: "sidebar-item",
  components: {
    [CollapseTransition.name]: CollapseTransition,
  },
  props: {
    hideItem: {
      type: String,
      default: () => "",
    },
    menu: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        };
      },
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    addChild(item) {
      // eslint-disable-next-line vue/require-slots-as-functions
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join("");
    },
    resetFilters() {
      localStorage.removeItem("filtersValoracion");
      localStorage.removeItem("filtersMasivo");
      localStorage.removeItem("filtersEnvio");
      localStorage.removeItem("filtersEnviosAgrupados");
      localStorage.removeItem("filtersRemesas");
      localStorage.removeItem("filtersCliente");
      localStorage.removeItem("filtersDireccionHabitual");
      localStorage.removeItem("filtersAlbaran");
      localStorage.removeItem("filterLiberarApply");
      localStorage.removeItem("filterRemesarApply");
      localStorage.removeItem("filtersTrafico");
      localStorage.removeItem("filtersTarifas");
      localStorage.removeItem("filtersSuplementos");
      localStorage.removeItem("filtersTarifasBase");
      localStorage.removeItem("filtersRecogidas");
      localStorage.removeItem("filtersEntregas");
      localStorage.removeItem("filtersRecogidasOtrasPl");
      localStorage.removeItem("filtersRecogidaProgramadas");
      localStorage.removeItem("filtersTarifaMaestros");
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  unmount() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
