import {
  formasPagoService
} from '@/_services';

export const formasPago = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getFormasPagoRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelFormasPagoRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getFormasPagoSuccess(state, formasPago) {
      state.list.data = formasPago;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFormasPago({ commit }, filters) {
      commit('getFormasPagoRequest');
      formasPagoService
        .getFormasPago(filters)
        .then((formasPago) => {
          commit('getFormasPagoSuccess', formasPago);
        })
        .catch((error) => {
          commit('cancelFormasPagoRequest', error);
        });
    },
  },
};