var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { attrs: { "aria-label": "breadcrumb", role: "navigation" } },
    [_c("ol", { staticClass: "breadcrumb" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }