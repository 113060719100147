import { render, staticRenderFns } from "./NotificationSample.vue?vue&type=template&id=263b115f&"
import script from "./NotificationSample.vue?vue&type=script&lang=js&"
export * from "./NotificationSample.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassiantemp/11dc8472-7ae2-5dbf-bec8-d9a1dcad8517/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('263b115f')) {
      api.createRecord('263b115f', component.options)
    } else {
      api.reload('263b115f', component.options)
    }
    module.hot.accept("./NotificationSample.vue?vue&type=template&id=263b115f&", function () {
      api.rerender('263b115f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Dashboard/Components/NotificationSample.vue"
export default component.exports