import { libretaContactoService } from '@/_services';

export const libretaContacto = {
  namespaced: true,
  state: {
    contacto: {
      data: [],
      status: {},
    },
    nuevo: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getContactoDireccionRequest(state) {
      state.contacto.status = {
        loading: true,
      };
    },
    getContactoDireccionSuccess(state, env) {
      state.contacto.data = env;
      state.contacto.status = {
        loaded: true,
      };
    },
    getContactoDireccionError(state, error){
      state.contacto.data = null
      state.contacto.status={
        error
      }
    },
    newContactoDireccionRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    newContactoDireccionSuccess(state, env) {
      state.nuevo.data = env;
      state.nuevo.status = {
        loaded: true,
      };
    },
    newContactoDireccionError(state, error){
      state.nuevo.data = null
      state.nuevo.status={
        error
      }
    },
    updateContactoDireccionRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateContactoDireccionSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        loaded: true,
      };
    },
    updateContactoDireccionError(state, error){
      state.update.data = null
      state.update.status={
        error
      }
    }
  },
  actions: {
    getContactoDireccion({ commit }, clienteYcontacto) {
      commit('getContactoDireccionRequest');
      libretaContactoService.getContactoDireccion(clienteYcontacto.clienteId,clienteYcontacto.contactoId)
        .then((contact) => {
          commit('getContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('getContactoDireccionError', error);
        });
    },
    newContactoDireccion({ commit }, nuevo) {
      commit('newContactoDireccionRequest');
      libretaContactoService.newContactoDireccion(nuevo.clienteId,nuevo.body)
        .then((contact) => {
          commit('newContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('newContactoDireccionError', error);
        });
    },
    updateContactoDireccion({ commit }, update) {
      commit('updateContactoDireccionRequest');
      libretaContactoService.updateContactoDireccion(update.clienteId,update.contactoId,update.body)
        .then((contact) => {
          commit('updateContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('updateContactoDireccionError', error);
        });
    },
  },
};