import createAxios from "./axiosHttpMarconi";
export const estadoService = { getEstados };

function getEstados(params) {
  const axios = createAxios();
  let urlPath = `/estados`;
  if (params.tipo) {
    urlPath += `?tipo=${params.tipo}`;
  }
  if (params.tipoTrafico) {
    urlPath += `&tipoTrafico=${params.tipoTrafico}`;
  }
  urlPath += `&sort=_id&order=ascending`;
  return axios.get(urlPath);
}
