/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'

// import './assets/css/element-ui/theme-chalk/index.css';
import locale from './assets/css/element-ui/locale/lang/es'
import VueTheMask from 'vue-the-mask'

import {
  store
} from './_store/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

import ElementUI from 'element-ui';
import LightBootstrap from './light-bootstrap-main'

// Plugins
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps';
import { apiKeyMaps } from './globalVars';
import { franquiciaService } from '@/_services';

// plugin setup
Vue.use(VueGoogleMaps, {
  load: {
    key: apiKeyMaps,
    libraries: "places",
  },
});
Vue.use(VueTheMask)
Vue.use(BootstrapIconsPlugin);
Vue.use(ElementUI, {
  locale
});

Vue.use(LightBootstrap)

import { router } from './routes'


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router
});

franquiciaService.loadFranquicia().catch((reason) => console.log(reason));
franquiciaService.reloadFranquicia();
localStorage.removeItem("userName");
