var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col-xl-2 col-md-3 col-sm-12" },
            [
              _c(
                "stats-card",
                {
                  attrs: {
                    title: _vm.dashboard.totals.national.toString(),
                    subTitle: "Nacionales",
                  },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("i", { staticClass: "fa fa-home text-secondary" }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("i", { staticClass: "fa fa-calendar" }),
                    _vm._v(
                      "Mes anterior: " +
                        _vm._s(_vm.dashboard.totals.national_last) +
                        " "
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xl-2 col-md-3 col-sm-12" },
            [
              _c(
                "stats-card",
                {
                  attrs: {
                    title: _vm.dashboard.totals.international.toString(),
                    subTitle: "Internacionales",
                  },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("i", { staticClass: "fa fa-globe text-secondary" }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("i", { staticClass: "fa fa-calendar" }),
                    _vm._v(
                      "Mes anterior: " +
                        _vm._s(_vm.dashboard.totals.international_last) +
                        " "
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._l(_vm.dashboard.couriers_total, function (courier, key) {
            return _c(
              "div",
              { key: key, staticClass: "col-xl-2 col-md-3 col-sm-12" },
              [
                _c(
                  "stats-card",
                  {
                    attrs: {
                      title: courier.totales.current_month.toString(),
                      subTitle: _vm.get_courier_text(courier.courier),
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "45px",
                        },
                        attrs: {
                          src: _vm.get_courier_logo(courier.courier),
                          alt: courier.courier,
                        },
                      }),
                    ]),
                    _c("template", { slot: "footer" }, [
                      _c("i", { staticClass: "fa fa-calendar" }),
                      _vm._v(
                        "Mes anterior: " +
                          _vm._s(courier.totales.latest_month.toString()) +
                          " "
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-5 col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(" Top 10 de ubicaciones de entrega este mes "),
                ]),
                _vm.loading
                  ? _c("el-skeleton", {
                      staticStyle: {
                        width: "100%",
                        padding: "10px",
                        height: "314px",
                      },
                      attrs: { animated: "", rows: 6 },
                    })
                  : _c("CitiesPieChart", {
                      staticClass: "p-2",
                      attrs: { data: _vm.dashboard.cities },
                    }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-7 col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Envíos por transporte este mes"),
                ]),
                _vm.loading
                  ? _c("el-skeleton", {
                      staticStyle: {
                        width: "100%",
                        padding: "10px",
                        height: "314px",
                      },
                      attrs: { animated: "", rows: 6 },
                    })
                  : _c("PartnersChart", {
                      staticClass: "p-2",
                      attrs: { data: _vm.dashboard.couriers },
                    }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Top 10 servicios este mes"),
                ]),
                _vm.loading
                  ? _c("el-skeleton", {
                      staticStyle: {
                        width: "100%",
                        padding: "10px",
                        height: "300px",
                      },
                      attrs: { animated: "", rows: 4 },
                    })
                  : _c("ServicesBarChart", {
                      staticClass: "p-2",
                      attrs: { data: _vm.dashboard.services },
                    }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-6 col-sm-12" },
          [
            _c("Notifications", {
              attrs: { data: _vm.dashboard.notifications },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }