//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    downloadFile() {
      this.$store.dispatch(
        "dashboard/getNotificationFile",
        this.notification._id
      );
    },
    isNew() {
      const creado = moment(this.notification.createdAt).format("YYYY-MM-DD");
      const hoy = moment().format("YYYY-MM-DD");
      return creado === hoy;
    },
  },
};
