import { franquiciaService } from '@/_services';

export const franquicia = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    clientes: {
      data: [],
      status: {}
    },
    clientesExcel: {
      data: null,
      status: {}
    },
    dirHabit: {
      data: null,
      status: {}
    },
    delDirecc: {
      data: null,
      status: {}
    },
    update: {
      data: [],
      status: {}
    },
    plantillas:{
      data: [],
      status: {}
    },
    eliminarPlantilla:{
      data: [],
      status: {}
    },
    programar:{
      data: [],
      status: {}
    }
  },
  mutations: {
    getPlantillasRequest(state) {
      state.plantillas.status = {
        loading: true,
      };
    },
    getPlantillasSuccess(state, direccion) {
      state.plantillas.data = direccion;
      state.plantillas.status = {
        loaded: true,
      };
    },
    getPlantillError(state, error) {
      state.plantillas.data = [];
      state.plantillas.status = {
        error,
      };
    },

    eliminarPlantillasRequest(state) {
      state.eliminarPlantilla.status = {
        loading: true,
      };
    },
    eliminarPlantillasSuccess(state, direccion) {
      state.eliminarPlantilla.data = direccion;
      state.eliminarPlantilla.status = {
        loaded: true,
      };
    },
    eliminarPlantillasError(state, error) {
      state.eliminarPlantilla.data = [];
      state.eliminarPlantilla.status = {
        error,
      };
    },

    eliminarDireccionHabitualRequest(state) {
      state.delDirecc.status = {
        loading: true,
      };
    },
    eliminarDireccionHabitualSuccess(state, direccion) {
      state.delDirecc.data = direccion;
      state.delDirecc.status = {
        loaded: true,
      };
    },
    eliminarDireccionHabitualError(state, error) {
      state.delDirecc.data = [];
      state.delDirecc.status = {
        error,
      };
    },
    getDirHabitByFranquiciaRequest(state) {
      state.dirHabit.status = {
        loading: true,
      };
    },
    cancelDirHabitByFranquiciaRequest(state, error) {
      state.dirHabit.data = [];
      state.dirHabit.status = {
        error,
      };
    },
    getDirHabitByFranquiciaSuccess(state, franquicia) {
      state.dirHabit.data = franquicia;
      state.dirHabit.status = {
        loaded: true,
      };
    },
    getFranquiciasByIdRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelFranquiciasByIdRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getFranquiciasByIdSuccess(state, franquicia) {
      state.list.data = franquicia;
      state.list.status = {
        loaded: true,
      };
    },
    getAllClientesFranquiciaRequest(state) {
      state.clientes.status = {
        loading: true
      }
    },
    getAllClientesFranquiciaSuccess(state, clientes) {
      state.clientes.data = clientes
      state.clientes.status = {
        loaded: true
      }
    },
    getAllClientesFranquiciaError(state, error) {
      state.clientes.data = null
      state.clientes.status = {
        error
      }
    },
    clientesExcelRequest(state) {
      state.clientesExcel.status = {
        loading: true
      }
    },
    clientesExcelSuccess(state, pdf) {
      state.clientesExcel.data = pdf
      state.clientesExcel.status = {
        loaded: true
      }
    },
    clientesExcelError(state, error) {
      state.clientesExcel.data = null
      state.clientesExcel.status = {
        error
      }
    },
    editFranquiciaRequest(state) {
      state.update.status = {
        editing: true,
      };
    },
    editFranquiciaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    editFranquiciaSuccess(state, franquiciaEditada) {
      state.update.data = franquiciaEditada;
      state.update.status = {
        edited: true,
      };
    },

    editProgramarPlantillaRequest(state) {
      state.programar.status = {
        editing: true,
      };
    },
    editProgramarPlantillaError(state, error) {
      state.programar.data = null;
      state.programar.status = {
        error,
      };
    },
    editProgramarPlantillaSuccess(state, pl) {
      state.programar.data = pl;
      state.programar.status = {
        edited: true,
      };
    },
  },
  actions: {
    clienteExcel({ commit }, request) {
      commit('clientesExcelRequest');
      franquiciaService
        .getClientesAsExcel(request)
        .then((excel) => {
          commit('clientesExcelSuccess', excel);
        })
        .catch((error) => {
          commit('clientesExcelError', error);
        });
    },
    getFranquiciasById({ commit }, idFranquicia) {
      commit('getFranquiciasByIdRequest');
      franquiciaService
        .getFranquiciasById(idFranquicia)
        .then((franquicias) => {
          commit('getFranquiciasByIdSuccess', franquicias);
        })
        .catch((error) => {
          commit('cancelFranquiciasByIdRequest', error);
        });
    },
    getDireccionHabitualByFranquicia({ commit }, body) {
      commit('getDirHabitByFranquiciaRequest');
      franquiciaService
        .getDireccionHabitualByFranquicia(body)
        .then((franquicias) => {
          commit('getDirHabitByFranquiciaSuccess', franquicias);
        })
        .catch((error) => {
          commit('cancelDirHabitByFranquiciaRequest', error);
        });
    },
    getAllClientesFranquicia({ commit }, req) {
      commit("getAllClientesFranquiciaRequest");
      franquiciaService.getAllClientesFranquicia(req.pagination, req.filters)
        .then((clientes) => {
          commit('getAllClientesFranquiciaSuccess', clientes);
        })
        .catch((error) => {
          commit('getAllClientesFranquiciaError', error);
        });
    },
    eliminarDireccionByClienteId({ commit }, body) {
      commit('eliminarDireccionHabitualRequest');
      franquiciaService
        .eliminarDireccionByClienteId(body)
        .then((cliente) => {
          commit('eliminarDireccionHabitualSuccess', cliente);
        })
        .catch((error) => {
          commit('eliminarDireccionHabitualError', error);
        });
    },
    updateFranquicia({ commit }, body) {
      commit('editFranquiciaRequest');
      franquiciaService.updateFranquicia(body)
        .then((Res) => {
          commit('editFranquiciaSuccess', Res);
        })
        .catch((error) => {
          commit('editFranquiciaError', error);
        });
    },
    getPlantillas({ commit }, req) {
      commit('getPlantillasRequest');
      franquiciaService
        .getPlantillas(req.idCliente, req.pagination, req.filters)
        .then((plantillas) => {
          commit('getPlantillasSuccess', plantillas);
        })
        .catch((error) => {
          commit('getPlantillError', error);
        });
    },
    eliminarPlantilla({ commit }, req) {
      commit('eliminarPlantillasRequest');
      franquiciaService
        .eliminarPlantilla(req.idCliente, req.idPlantilla)
        .then((cliente) => {
          commit('eliminarPlantillasSuccess', cliente);
        })
        .catch((error) => {
          commit('eliminarPlantillasError', error);
        });
    },
    updateEnvioProgramado({ commit }, req) {
      commit('editProgramarPlantillaRequest');
      franquiciaService.updateEnvioProgramado(req.idCliente, req.idPlantilla, req.body)
        .then((Res) => {
          commit('editProgramarPlantillaSuccess', Res);
        })
        .catch((error) => {
          commit('editProgramarPlantillaError', error);
        });
    },
  },
};

