var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fade-transition", [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "alert",
            class: ["alert-" + _vm.type, { "alert-with-icon": _vm.icon }],
            attrs: { role: "alert" },
          },
          [
            !_vm.dismissible
              ? [
                  _vm.icon || _vm.$slots.icon
                    ? [
                        _vm._t("icon", function () {
                          return [
                            _c("span", {
                              class: _vm.icon,
                              attrs: { "data-notify": "icon" },
                            }),
                          ]
                        }),
                      ]
                    : _vm._e(),
                  _vm._t("default"),
                ]
              : [
                  _vm._t("dismiss-icon", function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: { click: _vm.dismissAlert },
                        },
                        [_c("i", { staticClass: "nc-icon nc-simple-remove" })]
                      ),
                    ]
                  }),
                  _vm.icon || _vm.$slots.icon
                    ? [
                        _vm._t("icon", function () {
                          return [
                            _c("span", {
                              class: _vm.icon,
                              attrs: { "data-notify": "icon" },
                            }),
                          ]
                        }),
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    { attrs: { "data-notify": "message" } },
                    [_vm._t("default")],
                    2
                  ),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }