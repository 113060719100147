//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EditPassword from "./EditPassword.vue";
import UsuarioActive from "./UsuarioActive.vue";

export default {
  components: { EditPassword, UsuarioActive },
  data() {
    return {
      activarUsuario: false,
      user: null,
      showModalEdit: false,
      busqueda: "",
      allUsuarios: [],
      loading: true,
    };
  },
  mounted() {
    this.getUsuarios();
  },
  computed: {
    usuarios() {
      return this.$store.state.usuario.list.status;
    },
  },
  methods: {
    hideModal() {
      this.showModalEdit = false;
      this.activarUsuario = false;
    },
    desactivarUsuario(usuario) {
      this.user = usuario;
      this.activarUsuario = true;
    },
    buscarUsuario(val) {
      if (val !== "") {
        this.$store.dispatch("usuario/getAllUsuarios", {
          name: val,
        });
      } else {
        this.getUsuarios();
      }
    },
    nuevoUsuario() {
      this.$router.push({ name: "NuevoUsuario", params: { edit: "false" } });
    },
    usuarioDetalle(usuario) {
      this.$router.push({
        name: "NuevoUsuario",
        params: { edit: "true", usuarioSelected: usuario },
      });
    },
    editPassword(usuario) {
      this.user = usuario;
      this.showModalEdit = true;
    },
    getUsuarios() {
      this.$store.dispatch("usuario/getAllUsuarios");
      this.loading = true;
    },
  },
  watch: {
    usuarios() {
      if (this.usuarios.loaded) {
        if (this.$store.state.usuario.list.data) {
          this.allUsuarios = this.$store.state.usuario.list.data;
          this.loading = false;
        }
      }
    },
  },
};
