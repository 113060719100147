import { vistaOperativaService } from '@/_services';

export const vistaOperativa = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getVistaOperativaByCodigoRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getVistaOperativaByCodigoError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    getVistaOperativaByCodigoSuccess(state, data) {
      state.entity.data = data;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getVistaOperativaByCodigo({ commit }, codigo) {
      commit('getVistaOperativaByCodigoRequest');
      vistaOperativaService.getVistaOperativaByCodigo(codigo)
        .then((vista) => {
          commit('getVistaOperativaByCodigoSuccess', vista);
        })
        .catch((error) => {
          commit('getVistaOperativaByCodigoError', error);
        });
    },
  },
};

