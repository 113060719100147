import createAxios from './axiosHttpMarconi';
export const paisService = {
  getPaises
};

function getPaises(filters) {
  const axios = createAxios();
  let urlPath = '/pais';
  if (filters && filters.tipo) {
    urlPath += `?tipo=${filters.tipo}`;
  } if (filters && filters.limit) {
    urlPath += `?limit=${filters.limit}`;
  }
  return axios.get(urlPath);
}