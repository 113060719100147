//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartId: {
      type: String,
      default: "Doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#41B883C5",
              "#E46751C5",
              "#00D9FFC5",
              "#DD1D16C5",
              "#FDE722C5",
              "#0A15EBC5",
              "#EB2DD5C5",
              "#FAB700C5",
              "#6D449FC5",
              "#59EA11C5",
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.formatData();
  },
  methods: {
    formatData() {
      for (const city of this.data) {
        this.chartData.labels.push(city.name);
        this.chartData.datasets[0].data.push(city.quantity);
      }
    },
  },
};
