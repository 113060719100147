var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "breadcrumb",
        [
          _c(
            "breadcrumb-item",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v("Breadcrumb 1"),
              ]),
            ],
            1
          ),
          _c(
            "breadcrumb-item",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v("Breadcrumb 2"),
              ]),
            ],
            1
          ),
          _c("breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v(" Current page "),
          ]),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v("Light Bootstrap Table Heading"),
            ]),
            _c("p", { staticClass: "card-category" }, [
              _vm._v("Created using Roboto Font Family"),
            ]),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "typo-line" }, [
              _c("h1", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 1")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h2", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 2")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h3", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 3")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h4", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 4")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h5", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 5")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h6", [
                _c("p", { staticClass: "category" }, [_vm._v("Header 6")]),
                _vm._v(" Light Bootstrap Table Heading "),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", [
                _c("span", { staticClass: "category" }, [_vm._v("Paragraph")]),
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam. "
                ),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Quote")]),
              _c("blockquote", [
                _c("p", [
                  _vm._v(
                    " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam. "
                  ),
                ]),
                _c("small", [_vm._v(" Steve Jobs, CEO Apple ")]),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Muted Text")]),
              _c("p", { staticClass: "text-muted" }, [
                _vm._v(
                  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet. "
                ),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Coloured Text")]),
              _c("p", { staticClass: "text-primary" }, [
                _vm._v(
                  " Text Primary - Light Bootstrap Table Heading and complex bootstrap dashboard you've ever seen on the internet. "
                ),
              ]),
              _c("p", { staticClass: "text-info" }, [
                _vm._v(
                  " Text Info - Light Bootstrap Table Heading and complex bootstrap dashboard you've ever seen on the internet. "
                ),
              ]),
              _c("p", { staticClass: "text-success" }, [
                _vm._v(
                  " Text Success - Light Bootstrap Table Heading and complex bootstrap dashboard you've ever seen on the internet. "
                ),
              ]),
              _c("p", { staticClass: "text-warning" }, [
                _vm._v(
                  " Text Warning - Light Bootstrap Table Heading and complex bootstrap dashboard you've ever seen on the internet. "
                ),
              ]),
              _c("p", { staticClass: "text-danger" }, [
                _vm._v(
                  " Text Danger - Light Bootstrap Table Heading and complex bootstrap dashboard you've ever seen on the internet. "
                ),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("h2", [
                _c("p", { staticClass: "category" }, [_vm._v("Small Tag")]),
                _vm._v(" header with small subcard-title "),
                _c("br"),
                _c("small", [_vm._v('".small" is a tag for the headers')]),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Lists")]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("h5", [_vm._v("Unordered List")]),
                  _c("ul", [
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                    _c("li", { staticClass: "list-unstyled" }, [
                      _c("ul", [
                        _c("li", [_vm._v("List Item")]),
                        _c("li", [_vm._v("List Item")]),
                        _c("li", [_vm._v("List Item")]),
                      ]),
                    ]),
                    _c("li", [_vm._v("List Item")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("h5", [_vm._v("Ordered List")]),
                  _c("ol", [
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("h5", [_vm._v("Unstyled List")]),
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("h5", [_vm._v("Inline List")]),
                  _c("ul", { staticClass: "list-inline" }, [
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                    _c("li", [_vm._v("List Item")]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Blockquotes")]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("h5", [_vm._v("Default Blockquote")]),
                  _c("blockquote", [
                    _c("p", [
                      _vm._v(
                        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("h5", [_vm._v("Blockquote with Citation")]),
                  _c("blockquote", [
                    _c("p", [
                      _vm._v(
                        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. "
                      ),
                    ]),
                    _c("small", [
                      _vm._v("Someone famous in "),
                      _c(
                        "cite",
                        { attrs: { "card-title": "Source card-title" } },
                        [_vm._v("Source card-title")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "typo-line" }, [
              _c("p", { staticClass: "category" }, [_vm._v("Code")]),
              _c("p", [
                _vm._v(" This is "),
                _c("code", [_vm._v(".css-class-as-code")]),
                _vm._v(
                  ", an example of an inline code element. Wrap inline code within a "
                ),
                _c("code", [_vm._v(" <code>...</code>")]),
                _vm._v("tag. "),
              ]),
              _c("pre", [
                _vm._v(
                  "1. #This is an example of preformatted text.\n\t\t\t\t\t\t\t2. #Here is another line of code"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }