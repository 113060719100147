import createAxios from "./axiosHttpMarconi";
function getRepartidores(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/repartidor`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=100";
  }
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.codigo) {
    urlPathFilters += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.nombre) {
    urlPathFilters += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.telefono) {
    urlPathFilters += `&telefono=${filters.telefono}`;
  }
  if (filters && filters.activo) {
    urlPathFilters += `&activo=${filters.activo}`;
  }
  return urlPathFilters;
}

export const repartidorService = {
  getRepartidores,
};
