var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.texto
    ? _c(
        "p",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top-center",
              value: _vm.texto,
              expression: "texto",
              modifiers: { "top-center": true },
            },
          ],
          staticClass: "texto click-hover",
        },
        [
          _c("i", { staticClass: "fa fa-filter" }),
          _vm._v(" " + _vm._s(_vm.texto) + " "),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }