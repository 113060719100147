import createAxios from "./axiosHttpMarconi";

function getTipoImpuestos(pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/impuestos-tipo`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=100";
  }
  return axios.get(urlPath);
}

export const impuestosService = {
  getTipoImpuestos,
};
