import { albaranService } from "@/_services";

export const albaran = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    conceptos: {
      data: [],
      status: {},
    },
    nuevo: {
      data: [],
      status: {},
    },
    edit: {
      data: [],
      status: {},
    },
    action: {
      data: {},
      status: {},
    },
    prefacturarGenerico: {
      data: {},
      status: {},
    },
    albaran: {
      data: {},
      status: {},
    },
    albaranesExcel: {
      data: null,
      status: {},
    },
  },
  mutations: {
    getAlbaranesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getAlbaranesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getAlbaranesSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    getConceptosRequest(state) {
      state.conceptos.status = {
        loading: true,
      };
    },
    getConceptosError(state, error) {
      state.conceptos.data = null;
      state.conceptos.status = {
        error,
      };
    },
    getConceptosSuccess(state, ficha) {
      state.conceptos.data = ficha;
      state.conceptos.status = {
        loaded: true,
      };
    },
    nuevoAlbaranRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    nuevoAlbaranError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoAlbaranSuccess(state, ficha) {
      state.nuevo.data = ficha;
      state.nuevo.status = {
        loaded: true,
      };
    },
    editAlbaranRequest(state) {
      state.edit.status = {
        loading: true,
      };
    },
    editAlbaranError(state, error) {
      state.edit.data = null;
      state.edit.status = {
        error,
      };
    },
    editAlbaranSuccess(state, ficha) {
      state.edit.data = ficha;
      state.edit.status = {
        loaded: true,
      };
    },
    putActionsAlbaranesRequest(state) {
      state.action.status = {
        changing: true,
      };
    },
    putActionsAlbaranesError(state, error) {
      state.action.data = null;
      state.action.status = {
        error,
      };
    },
    putActionsAlbaranesSuccess(state, res) {
      state.action.data = res;
      state.action.status = {
        changed: true,
      };
    },
    putPrefacturarAlbaranesGenericosRequest(state) {
      state.prefacturarGenerico.status = {
        updating: true,
      };
    },
    putPrefacturarAlbaranesGenericosError(state, error) {
      state.prefacturarGenerico.data = null;
      state.prefacturarGenerico.status = {
        error,
      };
    },
    putPrefacturarAlbaranesGenericosSuccess(state, res) {
      state.prefacturarGenerico.data = res;
      state.prefacturarGenerico.status = {
        updated: true,
      };
    },
    getFichaAlbaranRequest(state) {
      state.albaran.status = {
        loading: true,
      };
    },
    getFichaAlbaranError(state, error) {
      state.albaran.data = null;
      state.albaran.status = {
        error,
      };
    },
    getFichaAlbaranSuccess(state, ficha) {
      state.albaran.data = ficha;
      state.albaran.status = {
        loaded: true,
      };
    },
    albaranesExcelRequest(state) {
      state.albaranesExcel.status = {
        loading: true,
      };
    },
    albaranesExcelSuccess(state, pdf) {
      state.albaranesExcel.data = pdf;
      state.albaranesExcel.status = {
        loaded: true,
      };
    },
    albaranesExcelError(state, error) {
      state.albaranesExcel.data = null;
      state.albaranesExcel.status = {
        error,
      };
    },
  },
  actions: {
    getAlbaranes({ commit }, req) {
      commit("getAlbaranesRequest");
      albaranService
        .getAlbaranes(req.pagination, req.filters)
        .then((albaranes) => {
          commit("getAlbaranesSuccess", albaranes);
        })
        .catch((error) => {
          commit("getAlbaranesError", error);
        });
    },
    getConceptos({ commit }) {
      commit("getConceptosRequest");
      albaranService
        .getConceptos()
        .then((conceptos) => {
          commit("getConceptosSuccess", conceptos);
        })
        .catch((error) => {
          commit("getConceptosError", error);
        });
    },
    nuevoAlbaran({ commit }, body) {
      commit("nuevoAlbaranRequest");
      albaranService
        .nuevoAlbaran(body)
        .then((response) => {
          commit("nuevoAlbaranSuccess", response);
        })
        .catch((error) => {
          commit("nuevoAlbaranError", error);
        });
    },
    editAlbaran({ commit }, body) {
      commit("editAlbaranRequest");
      albaranService
        .editAlbaran(body)
        .then((response) => {
          commit("editAlbaranSuccess", response);
        })
        .catch((error) => {
          commit("editAlbaranError", error);
        });
    },
    actionAlbaran({ commit }, req) {
      commit("putActionsAlbaranesRequest");
      albaranService
        .actionAlbaran(req)
        .then((res) => {
          commit("putActionsAlbaranesSuccess", res);
        })
        .catch((error) => {
          commit("putActionsAlbaranesError", error);
        });
    },
    prefacturarAlbaranesGenericos({ commit }, req) {
      commit("putPrefacturarAlbaranesGenericosRequest");
      albaranService
        .prefacturarAlbaranesGenericos(req)
        .then((res) => {
          commit("putPrefacturarAlbaranesGenericosSuccess", res);
        })
        .catch((error) => {
          commit("putPrefacturarAlbaranesGenericosError", error);
        });
    },
    getFichaAlbaran({ commit }, id) {
      commit("getFichaAlbaranRequest");
      albaranService
        .getFichaAlbaran(id)
        .then((response) => {
          commit("getFichaAlbaranSuccess", response);
        })
        .catch((error) => {
          commit("getFichaAlbaranError", error);
        });
    },
    albaranesExcel({ commit }, request) {
      commit("albaranesExcelRequest");
      albaranService
        .getAlbaranesAsExcel(request)
        .then((excel) => {
          commit("albaranesExcelSuccess", excel);
        })
        .catch((error) => {
          commit("albaranesExcelError", error);
        });
    },
  },
};
