//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { StatsCard } from "src/components/index";
import CitiesPieChart from "./Stats/CitiesPieChart.vue";
import PartnersChart from "./Stats/PartnersChart.vue";
import Notifications from "./Stats/Notifications.vue";
import ServicesBarChart from "./Stats/ServicesBarChart.vue";
export default {
  components: {
    StatsCard,
    CitiesPieChart,
    PartnersChart,
    Notifications,
    ServicesBarChart,
  },
  data() {
    return {
      dashboard: {
        totals: {
          international: 0,
          national: 0,
          total: 0,
          international_last: 0,
          national_last: 0,
          total_last: 0,
        },
        cities: [],
        notifications: [],
        services: [],
        couriers: [],
        couriers_total: [],
      },
      loading: false,
    };
  },
  mounted() {
    this.getDashboard();
  },
  computed: {
    dashboardStatus() {
      return this.$store.state.dashboard.entity.status;
    },
  },
  methods: {
    getDashboard() {
      this.$store.dispatch("dashboard/getDashboard");
    },
    notificacion(title, msg, typ) {
      this.$notify({
        title: title,
        message: msg,
        type: typ,
      });
    },
    get_courier_text(courier) {
      if (courier == "CORREOSEXPRESS") return "Correos Express";
      if (courier == "SEUR") return "Seur";
      if (courier == "UPS") return "Ups";
      if (courier == "OXPERTA") return "Oxperta";
      return courier;
    },
    get_courier_logo(courier) {
      switch (courier) {
        case "UPS":
          return `static/img/couriers/ups.svg`;
        case "SEUR":
          return `static/img/couriers/seur.svg`;
        case "CORREOSEXPRESS":
          return `static/img/couriers/correosexpress.svg`;
        case "OXPERTA":
          return `static/img/logo-mini.png`;
      }
      return courier;
    },
  },
  watch: {
    dashboardStatus(val) {
      if (val.loading) {
        this.loading = true;
      }

      if (val.error) {
        this.notificacion("Error", val.error.error, "danger");
        this.loading = false;
      }

      if (val.loaded) {
        this.dashboard = this.$store.state.dashboard.entity.data;
        this.loading = false;
      }
    },
  },
};
