//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  data() {
    return {
      ocultarItem: "mostrarme",
      ro: null,
      colorBackground: "black",
      menus: [],
      userLogged: null,
    };
  },
  computed: {
    menusStatus() {
      return this.$store.state.roles.menus.status;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isMenuVisible(route) {
      if (this.userLogged.roles.includes("ADMIN")) return true;
      const item = this.menus.find((item) => item.key == route);
      if (!item) return true;
      if (item.roles.length <= 0) return true;
      if (!this.userLogged.rolesMenu) {
        return false;
      }
      return item.roles.some((elemento) =>
        this.userLogged.rolesMenu.includes(elemento)
      );
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        docClasses.add("perfect-scrollbar-off");
      } else {
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      }
    },
    onResize() {
      let val = document.getElementById("sidebar").clientWidth;
      if (val == 80) {
        this.ocultarItem = "ocultarme";
      } else {
        this.ocultarItem = "mostrarme";
      }
    },
    getMenus() {
      this.$store.dispatch("roles/getMenus");
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.colorBackground = "black";
    } else this.colorBackground = "purple";
    this.$sidebar.ocultarSideBar();
    /* document.body.classList.toggle('sidebar-mini') */
    this.initScrollbar();
    this.ro = new ResizeObserver(this.onResize);
    let side = document.getElementById("sidebar");
    this.ro.observe(side);
    this.userLogged = JSON.parse(localStorage.getItem("user"));
    this.getMenus();
  },
  beforeUnmount() {
    let side = document.getElementById("sidebar");
    this.ro.unobserve(side);
  },
  watch: {
    menusStatus(val) {
      if (val.loaded) {
        this.menus = this.$store.state.roles.menus.data.franquicia;
      }
    },
  },
};
