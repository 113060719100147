//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import changePassword from "src/pages/Views/Franquicia/CambiarContraseña.vue";
export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
    changePassword,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    agrupacionesCliente() {
      if (this.$store.state.agrupacionCliente.entity.data.nombres) {
        return this.$store.state.agrupacionCliente.entity.data.nombres;
      }
      return [];
    },
  },
  data() {
    return {
      activeNotifications: false,
      usuario: null,
      isClosed: true,
      showChangePassword: false,
      franquicias: [],
      isAgrupacion: false,
      franquiciaSelected: "",
      agrupacionClienteSelected: "",
    };
  },
  mounted() {
    this.minimizeSidebar();
    this.usuario = JSON.parse(localStorage.getItem("user"));
    if (this.usuario.agrupacion) this.loadSelectFranquicias();
    this.getAgrupacionCliente();
    this.agrupacionClienteSelected = localStorage.getItem(
      "agrupacionClienteSelected"
    )
      ? localStorage.getItem("agrupacionClienteSelected")
      : "";
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    abrirSideBar() {
      this.$sidebar.toggleMinimize();
    },
    minimizeSidebar() {
      this.$sidebar.ocultarSideBar();
    },
    cerrarSesion() {
      localStorage.removeItem("user");
      localStorage.removeItem("agrupacionClienteSelected");
      localStorage.removeItem("empleado");
      this.$router.push({ name: "Login" });
    },
    showHideModalChangePassword() {
      this.showChangePassword = !this.showChangePassword;
    },
    cambiarPassword() {
      this.showHideModalChangePassword();
    },
    loadSelectFranquicias() {
      this.isAgrupacion = true;
      this.franquicias = this.usuario.agrupacion;
      this.franquiciaSelected = this.usuario.extraData.franquiciaId;
    },
    franquiciaChange(val) {
      this.usuario.extraData.franquiciaId = val;
      localStorage.setItem("franquicia", JSON.stringify({ _id: val }));
      localStorage.setItem("user", JSON.stringify(this.usuario));
      window.location.reload();
    },
    clienteChange(val) {
      this.usuario.extraData.franquiciaId = val;
      localStorage.setItem("agrupacionClienteSelected", val);
      window.location.reload();
    },
    getAgrupacionCliente() {
      this.$store.dispatch("agrupacionCliente/getAgrupacionCliente");
    },
  },
};
