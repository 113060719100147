import {
  vencimientosService
} from '@/_services';

export const vencimientos = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getVencimientosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelVencimientosRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getVencimientosSuccess(state, vencimientos) {
      state.list.data = vencimientos;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getVencimientos({ commit }) {
      commit('getVencimientosRequest');
      vencimientosService
        .getVencimientos()
        .then((vencimientos) => {
          commit('getVencimientosSuccess', vencimientos);
        })
        .catch((error) => {
          commit('cancelVencimientosRequest', error);
        });
    },
  },
};