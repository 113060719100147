//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { appVersion } from '@/globalVars';
export default {
  components: {
  },
  data() {
    return {
      version: appVersion,
    };
  },
}

