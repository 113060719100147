import { createAxios, createAxiosHeaders } from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

function convertirLocal(idEnvio) {
  const axios = createAxios();
  let body = { courier: "OXPERTA" };
  const franquicia = franquiciaService.getLocalFranquicia();
  const urlPath = `/franquicia/${franquicia._id}/envio/${idEnvio}/courier`;
  return axios.put(urlPath, body);
}
function logisticaInversa(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  const urlPath = `/franquicia/${franquicia._id}/envio/${body._id}/invertir`;
  return axios.post(urlPath, body);
}

function sendEnvio(body) {
  const axios = createAxios();
  const urlPath = "/envio";
  return axios.post(urlPath, body);
}

function newEventoEnvio(envio_id, body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `/franquicia/${franquicia._id}/envio/${envio_id}/estados`;
  return axios.post(urlPath, body);
}

function getAllEnvios(pagination, filters) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio`;

  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }
  if (filters && filters.numeroRecogida) {
    urlPath += `&numeroRecogida=${filters.numeroRecogida}`;
  }
  if (filters && filters.numeroEntrega) {
    urlPath += `&numeroEntrega=${filters.numeroEntrega}`;
  }

  if (filters && filters.gestor && filters.gestor) {
    urlPath += `&gestorResponsable=${filters.gestor}`;
  }

  if (filters && filters.localizadorBulto) {
    urlPath += `&localizadorBulto=${filters.localizadorBulto}`;
  }

  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }

  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }

  if (filters && filters.destinatario) {
    urlPath += `&destinatario=${filters.destinatario}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.repartidor) {
    urlPath += `&repartidor=${filters.repartidor}`;
  }

  if (filters && filters.tipo_devolucion) {
    urlPath += `&tipo_devolucion=${filters.tipo_devolucion}`;
  }

  if (filters && filters.courierMillaIntermedia) {
    urlPath += `&courierMillaIntermedia=${filters.courierMillaIntermedia}`;
  }

  if (filters && filters.date) {
    urlPath += `&dateStart=${filters.date[0]}`;
    urlPath += `&dateEnd=${filters.date[1]}`;
  }

  if (typeof filters.anomalia == "boolean" && filters.anomalia) {
    urlPath += "&anomalia=" + filters.anomalia;
  }

  if (typeof filters.reembolso == "boolean" && filters.reembolso) {
    urlPath += "&reembolso=" + filters.reembolso;
  }

  if (filters && filters.recogida) {
    urlPath += `&recogida=${filters.recogida}`;
  }

  if (filters && filters.entrega) {
    urlPath += `&entrega=${filters.entrega}`;
  }

  if (filters && filters.queryBase) {
    urlPath += `&queryBase=${filters.queryBase}`;
  }

  if (filters && filters.codigo_vista) {
    urlPath += `&codigo_vista=${filters.codigo_vista}`;
  }

  if (
    typeof filters.origenEnvioProgramado == "boolean" &&
    filters.origenEnvioProgramado
  ) {
    urlPath += "&app=PLANTILLA";
  }

  if (typeof filters.origenRma == "boolean" && filters.origenRma) {
    urlPath += "&app=RMA";
  }

  if (typeof filters.entregaSabado == "boolean" && filters.entregaSabado) {
    urlPath += `&entregaSabado=${filters.entregaSabado}`;
  }

  return axios.get(urlPath);
}

function getEnvio(envioId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${envioId}`;
  return axios.get(urlPath);
}

function getValoracionSimular(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/valoracion_simular/`;
  return axios.post(urlPath, body);
}

function getDirHabituales(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/cliente/${body.clientId}/direcciones`;
  if (body.page != null) {
    urlPath += `?page=${body.page}&limit=${body.limit}&`;
  } else {
    urlPath += "?";
  }
  if (body.tipo) {
    urlPath += `tipo=${body.tipo}&`;
  }

  if (body.order) {
    urlPath += `order=${body.order}&`;
  }

  if (body.sort) {
    urlPath += `sort=${body.sort}&`;
  }

  if (body.normalizada) {
    urlPath += `normalizada=${body.normalizada}`;
  }
  return axios.get(urlPath);
}

function modificarEstado(envioId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${envioId}/estados`;
  let body = { codigo: "19" };
  return axios.post(urlPath, body);
}

function envioEtiquetaFranquicia(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  const urlPath = `franquicia/${franquicia._id}/envio/etiqueta`;
  return axios.post(urlPath, body, {
    responseType: "blob",
  });
}

function envioEtiquetaMail(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  const urlPath = `franquicia/${franquicia._id}/envio/etiqueta/mail`;
  return axios.post(urlPath, body);
}

function getEstadosEnvio(envioId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${envioId}/estados`;
  return axios.get(urlPath);
}

function getEnviosAsExcel(filters) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `/franquicia/${franquicia._id}/envio/excel?limit=2000`;

  if (filters && filters.date) {
    urlPath += `&dateStart=${filters.date[0]}`;
    urlPath += `&dateEnd=${filters.date[1]}`;
  }

  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }

  if (filters && filters.localizadorBulto) {
    urlPath += `&localizadorBulto=${filters.localizadorBulto}`;
  }

  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }

  if (filters && filters.tipo_devolucion) {
    urlPath += `&tipo_devolucion=${filters.tipo_devolucion}`;
  }

  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }

  if (filters && filters.alias) {
    urlPath += `&alias=${filters.alias}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.repartidor) {
    urlPath += `&repartidor=${filters.repartidor}`;
  }

  if (filters && filters.idFiscal) {
    urlPath += `&idFiscal=${filters.idFiscal}`;
  }

  if (filters && filters.codigo) {
    urlPath += `&codigo=${filters.codigo}`;
  }

  if (typeof filters.anomalia == "boolean" && filters.anomalia) {
    urlPath += "&anomalia=" + filters.anomalia;
  }

  if (typeof filters.reembolso == "boolean" && filters.reembolso) {
    urlPath += "&reembolso=" + filters.reembolso;
  }

  if (filters && filters.recogida) {
    urlPath += `&recogida=${filters.recogida}`;
  }

  if (filters && filters.queryBase) {
    urlPath += `&queryBase=${filters.queryBase}`;
  }

  if (filters && filters.codigo_vista) {
    urlPath += `&codigo_vista=${filters.codigo_vista}`;
  }

  if (typeof filters.entregaSabado == "boolean" && filters.entregaSabado) {
    urlPath += `&entregaSabado=${filters.entregaSabado}`;
  }

  if (filters && filters.numeroRecogida) {
    urlPath += `&numeroRecogida=${filters.numeroRecogida}`;
  }

  if (filters && filters.numeroEntrega) {
    urlPath += `&numeroEntrega=${filters.numeroEntrega}`;
  }

  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getJustificanteEntrega(request) {
  const axios = createAxiosHeaders();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${request._id}/comprobante-entrega`;
  return axios.get(urlPath).then((response) => {
    if (response.data instanceof Object) return JSON.stringify(response.data);
    else {
      const byteCharacters = atob(response.data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      const type = response.headers["content-type"];
      return new Blob([byteArray], { type });
    }
  });
}

function getJustificanteServicio(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/envio/${request}/justificante-servicio`;
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function getJustificanteRecogida(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/envio/${request}/comprobante-recogida`;
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function sendEnvioProgramado(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquicia/${user.extraData.franquiciaId}/cliente/${request.envio.cliente}/plantilla`;
  return axios.post(urlPath, request);
}

function getPaperLessEnvio(envioId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${envioId}/documentacion`;
  return axios.get(urlPath);
}

function getPaperLessFile(envioId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${envioId}/documentacion`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function downloadPaperless(body) {
  const axios = createAxiosHeaders();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${body.envioId}/documentacion/download`;
  return axios
    .post(urlPath, body)
    .then((response) => {
      const byteCharacters = atob(response.data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      const type = response.headers["content-type"];
      console.log(response.headers);
      return {
        file: new Blob([byteArray], { type }),
        filename: response.headers["content-disposition"],
      };
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function eliminarPaperless(body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/envio/${body.envioId}/documentacion/delete`;
  return axios.post(urlPath, body);
}

function editarBultos(idEnvio, body) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/local/envio/${idEnvio}`;
  return axios.put(urlPath, body);
}

export const envioService = {
  getPaperLessEnvio,
  getAllEnvios,
  sendEnvio,
  getDirHabituales,
  getEnvio,
  modificarEstado,
  envioEtiquetaFranquicia,
  getEnviosAsExcel,
  getEstadosEnvio,
  newEventoEnvio,
  getJustificanteEntrega,
  sendEnvioProgramado,
  logisticaInversa,
  getValoracionSimular,
  getJustificanteServicio,
  getJustificanteRecogida,
  convertirLocal,
  envioEtiquetaMail,
  getPaperLessFile,
  downloadPaperless,
  eliminarPaperless,
  editarBultos,
};
