import createAxios from './axiosHttpMarconi';
import { franquiciaService } from './franquicia.service';

function getValoraciones(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/valoracion/control`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=20';
  }

  if (filters && filters.date) {
    urlPath += `&fechaDesde=${filters.date[0]}`;
    urlPath += `&fechaHasta=${filters.date[1]}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }

  if (filters && filters.sinValoracion) {
    urlPath += `&sinValoracion=${filters.sinValoracion}`;
  }

  if (filters && filters.diferenciaValoracion) {
    urlPath += `&diferenciaValoracion=${filters.diferenciaValoracion}`;
  }

  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.estadoEnvio && filters.estadoEnvio.length > 0) {
    urlPath += `&estadoEnvio=${filters.estadoEnvio.join()}`;
  }

  if (filters && filters.porcentaje) {
    urlPath += `&porcentaje=${filters.porcentaje}`;
  }

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }


  return axios.get(urlPath);
}

function changeValoraciones(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/valoracion`;
  return axios.put(urlPath, req);
}

function applyValoraciones(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/valoracion/control`;
  return axios.put(urlPath, req);
}

function getValoracion(valoracionId) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/valoracion/${valoracionId}`;
  return axios.get(urlPath);
}

function getValoracionesAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));

  let urlPath = `/franquicia/${user.extraData.franquiciaId}/valoraciones/excel?limit=2000`;
  if (filters && filters.date) {
    urlPath += `&fechaDesde=${filters.date[0]}`;
    urlPath += `&fechaHasta=${filters.date[1]}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }

  if (filters && filters.sinValoracion) {
    urlPath += `&sinValoracion=${filters.sinValoracion}`;
  }

  if (filters && filters.diferenciaValoracion) {
    urlPath += `&diferenciaValoracion=${filters.diferenciaValoracion}`;
  }

  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.estadoEnvio && filters.estadoEnvio.length > 0) {
    urlPath += `&estadoEnvio=${filters.estadoEnvio.join()}`;
  }

  if (filters && filters.porcentaje) {
    urlPath += `&porcentaje=${filters.porcentaje}`;
  }

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

export const valoracionService = {
  getValoraciones, changeValoraciones, applyValoraciones, getValoracion, getValoracionesAsExcel
};
