import {
  exportacionService
} from '@/_services';

export const exportacionContable = {
  namespaced: true,
  state: {
    new: {
      data: [],
      status: {},
    },
    exportacionInfo: {
      data: [],
      status: {},
    },
    updateExportacion: {
      data: [],
      status: {},
    }
  },
  mutations: {
    newExportacionContableRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newExportacionContableError(state, error) {
      state.new.data = null;
      state.new.status = {
        error,
      };
    },
    newExportacionContableSuccess(state, exportacion) {
      state.new.data = exportacion;
      state.new.status = {
        created: true,
      };
    },
    updateExportacionContableRequest(state) {
      state.updateExportacion.status = {
        updating: true,
      };
    },
    updateExportacionContableError(state, error) {
      state.updateExportacion.data = null;
      state.updateExportacion.status = {
        error,
      };
    },
    updateExportacionContableSuccess(state, exportacion) {
      state.updateExportacion.data = exportacion;
      state.updateExportacion.status = {
        updated: true,
      };
    },
    getExportacionContableRequest(state) {
      state.exportacionInfo.status = {
        loading: true,
      };
    },
    getExportacionContableError(state, error) {
      state.exportacionInfo.data = [];
      state.exportacionInfo.status = {
        error,
      };
    },
    getExportacionContableSuccess(state, exportacion) {
      state.exportacionInfo.data = exportacion;
      state.exportacionInfo.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getExportacionContable({ commit }, idFranquicia) {
      commit('getExportacionContableRequest');
      exportacionService
        .getExportacionContable(idFranquicia)
        .then((exportacion) => {
          commit('getExportacionContableSuccess', exportacion);
        })
        .catch((error) => {
          commit('getExportacionContableError', error);
        });
    },
    nuevaExportacionContable({ commit }, body) {
      commit('newExportacionContableRequest');
      exportacionService
        .nuevaExportacionContable(body)
        .then((cp) => {
          commit('newExportacionContableSuccess', cp);
        })
        .catch((error) => {
          commit('newExportacionContableError', error);
        });
    },
    updateExportacionContable({ commit }, body) {
      commit('updateExportacionContableRequest');
      exportacionService
        .updateExportacionContable(body)
        .then((cp) => {
          commit('updateExportacionContableSuccess', cp);
        })
        .catch((error) => {
          commit('updateExportacionContableError', error);
        });
    },
  },
};