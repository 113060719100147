var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-layout", { staticClass: "not-found-page" }, [
    _c("div", { staticClass: "centered" }, [
      _c("h1", [
        _c("i", { staticClass: "not-found-icon nc-icon nc-puzzle-10" }),
        _vm._v("404"),
      ]),
      _c("p", [_vm._v("The page you requested could not be found.")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }