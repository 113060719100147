var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    on: { hide: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v("Cambiando contraseña..."),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "contenedor" },
              [
                _c(
                  "el-form",
                  {
                    ref: "general",
                    attrs: { model: _vm.general, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: "Please input password",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.general.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.general, "password", $$v)
                            },
                            expression: "general.password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.hideModal },
              },
              [_vm._v(" Cancelar ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.editedpassword("general")
                  },
                },
              },
              [_vm._v(" Aceptar ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }