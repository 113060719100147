import createAxios from "./axiosHttpMarconi";
export const remesaService = {
  getRemesas,
  getRemesaById,
  updateRemesa,
  remesarRemesa,
  nuevaRemesa,
  eliminarRemesa,
  aprobarRemesa,
  liberarRemesa,
  getSepa,
};

function getRemesas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=fecha&order=descending`;
  }
  if (filters && filters.numeroRemesa) {
    urlPath += `&numeroRemesa=${filters.numeroRemesa}`;
  }
  if (filters && filters.fecha[0]) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
  }
  if (filters && filters.fecha[1]) {
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.entidadBancaria && filters.entidadBancaria != "") {
    urlPath += `&idEntidadBancaria=${filters.entidadBancaria}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  return axios.get(urlPath);
}

function nuevaRemesa(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa`;
  return axios.post(urlPath, body);
}
function aprobarRemesa(idRemesa, fechaAprobacion) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${idRemesa}/aprobar`;
  return axios.put(urlPath, { fechaAprobacion });
}
function eliminarRemesa(idRemesa) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${idRemesa}`;
  return axios.delete(urlPath);
}

function getRemesaById(idRemesa) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${idRemesa}`;
  return axios.get(urlPath);
}
function updateRemesa(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${body._id}`;
  return axios.put(urlPath, body);
}
function remesarRemesa(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${body._id}/efectos/remesar`;
  return axios.put(urlPath, body.request);
}
function liberarRemesa(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${body._id}/efectos/liberar`;
  return axios.put(urlPath, body.request);
}
function getSepa(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/remesa/${filters.idRemesa}/sepa`;
  if (filters && filters.plantilla) {
    urlPath += `?plantilla=${filters.plantilla}`;
  }
  if (filters && filters.adeudo) {
    urlPath += `&adeudo=${filters.adeudo}`;
  }
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
