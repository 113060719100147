//
//
//
//
//
//
//

export default {
  name: 'estado',
  props: {
    label: [String],
    type: {
      type: String,
      default: 'primary',
      description: 'Tipo Estado(primary|secondary|danger etc)'
    }
  },
  data () {
    return {
    }
  },
  mounted(){
  },
  computed: {
  },
  created () {
  }
}
