//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 285,
    },
    height: {
      type: Number,
      default: 285,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {
    this.formatData();
  },
  methods: {
    formatData() {
      for (const service of this.data) {
        this.chartData.datasets[0].backgroundColor.push(this.getRandomColor());
        this.chartData.datasets[0].data.push(service.quantity);
        this.chartData.labels.push(service.name);
      }
    },
    getRandomColor() {
      const h = Math.floor(Math.random() * 360);
      const s = Math.floor(Math.random() * 40 + 40) + "%"; // Saturación entre 40% y 80%
      const l = Math.floor(Math.random() * 30 + 35) + "%"; // Luminosidad entre 35% y 65%

      return `hsl(${h}, ${s}, ${l})`;
    },
  },
};
