var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wrapper wrapper-full-page" }, [
      _c(
        "nav",
        {
          staticClass:
            "navbar navbar-expand-lg navbar-transparent navbar-absolute",
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "navbar-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "navbar-toggler",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "aria-controls": "navigation-index",
                      "aria-expanded": "false",
                      "aria-label": "Toggle navigation",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showMenu = !_vm.showMenu
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "navbar-toggler-bar burger-lines",
                    }),
                    _c("span", {
                      staticClass: "navbar-toggler-bar burger-lines",
                    }),
                    _c("span", {
                      staticClass: "navbar-toggler-bar burger-lines",
                    }),
                  ]
                ),
              ]),
              _c("el-collapse-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMenu,
                        expression: "showMenu",
                      },
                    ],
                    staticClass: "navbar-collapse justify-content-end",
                    attrs: { id: "navbar" },
                  },
                  [_c("ul", { staticClass: "navbar-nav" })]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "full-page",
          class: _vm.pageClass,
          attrs: { "data-color": _vm.backgroundColor },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
          ]),
          _vm._m(0),
          _c("div", {
            staticClass: "full-page-background",
            staticStyle: {
              "background-image": "url(static/img/fondos/fondo1.png)",
            },
          }),
        ]
      ),
    ]),
    _c("div", { staticClass: "collapse navbar-collapse off-canvas-sidebar" }, [
      _c(
        "ul",
        { staticClass: "nav nav-mobile-menu" },
        [
          _c("router-link", { attrs: { to: "/register" } }, [
            _c("a", [_vm._v("Register")]),
          ]),
          _c("router-link", { attrs: { to: "/cliente/" } }, [
            _c("a", [_vm._v("Dashboard")]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer footer-transparent" }, [
      _c(
        "div",
        {
          staticClass:
            "\n             container\n             d-flex\n             flex-lg-row flex-column\n             justify-content-lg-between justify-content-center\n             align-items-center\n           ",
        },
        [_c("div", { staticClass: "copyright" }, [_vm._v(" v1.0.2 ")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }