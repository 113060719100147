//
//
//
//
//
//
//
//
//
//
//
//
//

export default{
  name: 'l-checkbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: [Array, Boolean],
    disabled: [Boolean, String],
    inline: Boolean
  },
  data () {
    return {
      cbId: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.checked
      },
      set (check) {
        this.$emit('input', check)
      }
    },
    inlineClass () {
      if (this.inline) {
        return `checkbox-inline`
      }

      return false
    }
  },
  created () {
    this.cbId = Math.random().toString(16).slice(2)
  }
}
