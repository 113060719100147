var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "nav",
          { staticClass: "col-10", attrs: { "aria-label": "breadcrumb" } },
          [
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item fs-3",
                  attrs: { "aria-current": "page" },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none",
                      attrs: { to: "/usuarios/listado" },
                    },
                    [_vm._v("Lista Usuarios")]
                  ),
                ],
                1
              ),
              _vm.valueEdit === "false"
                ? _c(
                    "li",
                    {
                      staticClass: "breadcrumb-item fs-3",
                      attrs: { "aria-current": "page" },
                    },
                    [_vm._v(" Nuevo usuario ")]
                  )
                : _vm._e(),
              _vm.valueEdit === "true"
                ? _c(
                    "li",
                    {
                      staticClass: "breadcrumb-item fs-3",
                      attrs: { "aria-current": "page" },
                    },
                    [_vm._v(" Editar usuario ")]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary ms-3",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.saveNuevoUsuario("general")
                },
              },
            },
            [_vm._v(" Guardar ")]
          ),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "general",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.general,
            "status-icon": "",
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Nombres", prop: "nombres" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "Nombres...",
                  size: "mini",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.general.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.general, "name", $$v)
                  },
                  expression: "general.name",
                },
              }),
            ],
            1
          ),
          _vm.general.externalprovider === "Ninguno"
            ? _c(
                "el-form-item",
                { attrs: { label: "Contraseña", prop: "pass" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "Contraseña...",
                      size: "mini",
                      type: "password",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.general.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.general, "password", $$v)
                      },
                      expression: "general.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "OwnerdId", prop: "ownerid" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "OwnerdId...",
                  size: "mini",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.general.ownerid,
                  callback: function ($$v) {
                    _vm.$set(_vm.general, "ownerid", $$v)
                  },
                  expression: "general.ownerid",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "ExternalId", prop: "externalid" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "ExternalId...",
                  size: "mini",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.general.externalid,
                  callback: function ($$v) {
                    _vm.$set(_vm.general, "externalid", $$v)
                  },
                  expression: "general.externalid",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "ExternalProvider", prop: "externalprovider" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini", placeholder: "Select" },
                  model: {
                    value: _vm.general.externalprovider,
                    callback: function ($$v) {
                      _vm.$set(_vm.general, "externalprovider", $$v)
                    },
                    expression: "general.externalprovider",
                  },
                },
                _vm._l(_vm.listProvider, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }