//
//
//
//

export default {
  props: {
    label: {
      required: true,
      default: () => ""
    },
    value: {
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
  },
  methods: {
    selectedItem(){
      this.$parent.selected(this.value, this.label);
      this.$parent.clickToggle();
    }
  },
  watch: {
  },
};
