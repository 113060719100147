//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Card from './Card.vue'
export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    title: String,
    subTitle: String
  }
}

