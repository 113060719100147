import createAxios from './axiosHttpMarconi';

function getVistaOperativaByCodigo(codigo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/vista?codigo=${codigo}`;
  return axios.get(urlPath);
}


export const vistaOperativaService = {
  getVistaOperativaByCodigo
};
