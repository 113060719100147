var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "clearfix" }, [
      _c("div", { staticClass: "float-start" }, [
        _c("b", [
          _vm.isNew()
            ? _c("span", { staticClass: "badge rounded-pill text-bg-danger" }, [
                _vm._v("Nuevo"),
              ])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.notification.nombre) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "float-end" },
        [
          _vm.notification.adjunto
            ? _c(
                "el-link",
                {
                  staticClass: "float-end",
                  attrs: {
                    icon: "el-icon-download",
                    type: "primary",
                    underline: false,
                  },
                  on: { click: _vm.downloadFile },
                },
                [_vm._v("Descargar fichero")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(_vm._s(_vm.notification.texto)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }