import { fechaService } from "@/_services";

export const fecha = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getFechaCierreRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getFechaCierreError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    getFechaCierreSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFechaCierre({ commit }, req) {
      commit("getFechaCierreRequest");
      fechaService
        .getFechaCierre(req)
        .then((response) => {
          commit("getFechaCierreSuccess", response);
        })
        .catch((error) => {
          commit("getFechaCierreError", error);
        });
    },
  },
};
