import { createAxios } from "./axiosHttpMarconi";
export const paradaService = {
  getParadas,
  getEnviosByParada,
  getManifiestoByParada,
  getEtiquetaPDFByParada,
  sendEmailEtiquetasByParada,
  anularParada,
  estadosParada,
  getImages,
  getJustificantePDFByParada,
  getParada,
  createRecogida,
};

function getParada(paradaId) {
  const axios = createAxios();
  let urlPath = `/parada/${paradaId}`;
  return axios.get(urlPath);
}

function getParadas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/paradas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.date) {
    urlPath += `&fecha_inicio=${filters.date[0]}`;
    urlPath += `&fecha_fin=${filters.date[1]}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.numero) {
    urlPath += `&numero=${filters.numero}`;
  }
  if (filters && filters.referenciaCourier) {
    urlPath += `&referenciaCourier=${filters.referenciaCourier}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.origen) {
    urlPath += `&origen=${filters.origen}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.courier) {
    urlPath += `&courier=${filters.courier}`;
  }

  if (typeof filters.enFranquicia == "boolean" && filters.enFranquicia) {
    urlPath += `&enFranquicia=${filters.enFranquicia}`;
  }

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }

  return axios.get(urlPath);
}
function getEnviosByParada(idParada, pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/envios`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  return axios.get(urlPath);
}

function getManifiestoByParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/manifiesto`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getEtiquetaPDFByParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/etiqueta/download`;
  return axios.get(urlPath, { responseType: "blob" });
}

function sendEmailEtiquetasByParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/etiqueta/mail`;
  return axios.get(urlPath);
}

function anularParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/anular`;
  return axios.post(urlPath);
}

function estadosParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/estados`;
  return axios.get(urlPath);
}

function getImages(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${id}/imagenes`;
  return axios.get(urlPath);
}
function getJustificantePDFByParada(idParada) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/parada/${idParada}/justificante`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function createRecogida(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/paradas/recogida`;
  return axios.post(urlPath, body);
}
