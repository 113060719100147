import createAxios from "./axiosHttpMarconi";
export const fechaService = { getFechaCierre };

function getFechaCierre(query) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/fecha_cierre?`;
  if (query.anio) {
    urlPath += `anio=${query.anio}&`;
  }
  if (query.mes) {
    urlPath += `mes=${query.mes}`;
  }
  return axios.get(urlPath);
}
