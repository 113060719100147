//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import changePassword from "src/pages/Views/Franquicia/CambiarContraseña.vue";
export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
    changePassword,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      usuario: null,
      isClosed: true,
      showChangePassword: false,
    };
  },
  mounted() {
    this.minimizeSidebar();
    this.usuario = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    abrirSideBar() {
      this.$sidebar.toggleMinimize();
    },
    minimizeSidebar() {
      this.$sidebar.ocultarSideBar();
    },
    cerrarSesion() {
      localStorage.removeItem("user");
      localStorage.removeItem("empleado");
      this.$router.push({ name: "Login" });
    },
    showHideModalChangePassword() {
      this.showChangePassword = !this.showChangePassword;
    },
    cambiarPassword() {
      this.showHideModalChangePassword();
    },
  },
};
