import { impuestosService } from "@/_services";
export const impuestos = {
  namespaced: true,
  state: {
    listTipos: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getTipoImpuestosRequest(state) {
      state.listTipos.status = {
        loading: true,
      };
    },
    getTipoImpuestosError(state, error) {
      state.listTipos.data = [];
      state.listTipos.status = {
        error,
      };
    },
    getTipoImpuestosSuccess(state, env) {
      state.listTipos.data = env;
      state.listTipos.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getTipoImpuestos({ commit }) {
      commit("getTipoImpuestosRequest");
      impuestosService
        .getTipoImpuestos()
        .then((env) => {
          commit("getTipoImpuestosSuccess", env);
        })
        .catch((error) => {
          commit("getTipoImpuestosError", error);
        });
    },
  },
};
