import createAxios from './axiosHttpMarconi';
import { franquiciaService } from './franquicia.service';
export const suplementoService = {
  nuevoSuplemento, getSuplementosCentral, getAllSuplementos, editarSuplemento,
  eliminarSuplemento, getAllSuplementosAsExcel
}
function eliminarSuplemento(suplementoId) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `franquicia/${idFranquicia._id}/suplemento/${suplementoId}`;
  return axios.delete(urlPath);
}

function nuevoSuplemento(data) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/suplemento`;
  return axios.post(urlPath, data);
}

function editarSuplemento(data) {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquicia/${franquicia._id}/suplemento/${data.suplemento}`;
  return axios.put(urlPath, data);
}

function getSuplementosCentral() {
  const axios = createAxios();
  let urlPath = `/conceptos?sort=nombre&order=ascending`;
  return axios.get(urlPath);
}

function getAllSuplementos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/suplementos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters)
  urlPath += '&sort=desde&order=descending'
  return axios.get(urlPath);
}

function getAllSuplementosAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/suplementos/excel?limit=2000`;
  urlPath += getFilters(filters)
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

function getFilters(filters) {
  let urlPath = '';
  if (filters && filters.codigo) {
    urlPath += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&cliente=${filters.idCliente}`;
  }
  urlPath += `&vigente=${filters.vigente}`;
  return urlPath
}