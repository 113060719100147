import { franquiciadoraService } from '@/_services';

export const franquiciadora = {
    namespaced: true,
    state: {
        entity: {
            data: [],
            status: {},
        },
    },
    mutations: {
        getFranquiciadoraRequest(state) {
            state.entity.status = {
                loading: true,
            };
        },
        cancelentityRequest(state, error) {
            state.entity.data = [];
            state.entity.status = {
                error,
            };
        },
        getFranquiciadoraSuccess(state, franquicia) {
            state.entity.data = franquicia;
            state.entity.status = {
                loaded: true,
            };
        },
    },
    actions: {
        getFranquiciadora({ commit }) {
            commit('getFranquiciadoraRequest');
            franquiciadoraService
                .getFranquiciadora()
                .then((franquicias) => {
                    commit('getFranquiciadoraSuccess', franquicias);
                })
                .catch((error) => {
                    commit('cancelentityRequest', error);
                });
        },
    },
};

