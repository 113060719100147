import { notificacionService } from '@/_services';

export const notificacion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {}
    },
    notificar: {
      data: {},
      status: {},      
    }
  },
  mutations: {
    getNotificacionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getNotificacionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getNotificacionesSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    notificarRequest(state) {
      state.notificar.status = {
        loading: true
      }
    },
    notificarSuccess(state, response) {
      state.notificar.data = response
      state.notificar.status = {
        loaded: true
      }
    },
    notificarError(state, error) {
      state.notificar.data = null
      state.notificar.status = {
        error
      }
    },
  },
  actions: {
    getNotificaciones({ commit }, req) {
      commit('getNotificacionesRequest');
      notificacionService.getNotificaciones(req.pagination, req.filters)
        .then((notificaciones) => {
          commit('getNotificacionesSuccess', notificaciones);
        })
        .catch((error) => {
          commit('getNotificacionesError', error);
        });
    },
    notificar({ commit }, request) {
      commit('notificarRequest');
      notificacionService
        .notificar(request)
        .then((notificacion) => {
          commit('notificarSuccess', notificacion);
        })
        .catch((error) => {
          commit('notificarError', error);
        });
    },
  },
}