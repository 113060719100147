import { valoracionService } from '@/_services';

export const valoracion  = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {}
    },
    revaloraciones: {
      data: {},
      status: {}
    },
    aplicaciones: {
      data: {},
      status: {}
    },
    valoracion: {
      data: {},
      status: {}
    },
    valoracionesExcel: {
      data: null,
      status: {}
    }
  },
  mutations: {
    getValoracionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getValoracionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getValoracionesSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    putRevaloracionesRequest(state) {
      state.revaloraciones.status = {
        revaluing: true,
      };
    },
    putRevaloracionesError(state, error) {
      state.revaloraciones.data = null;
      state.revaloraciones.status = {
        error,
      };
    },
    putRevaloracionesSuccess(state, data) {
      state.revaloraciones.data = data;
      state.revaloraciones.status = {
        revalued: true,
      };
    },
    putAplicacionesRequest(state) {
      state.aplicaciones.status = {
        applying: true,
      };
    },
    putAplicacionesError(state, error) {
      state.aplicaciones.data = null;
      state.aplicaciones.status = {
        error,
      };
    },
    putAplicacionesSuccess(state, data) {
      state.aplicaciones.data = data;
      state.aplicaciones.status = {
        applied: true,
      };
    },
    getValoracionRequest(state) {
      state.valoracion.status = {
        loading: true,
      };
    },
    getValoracionSuccess(state, env) {
      state.valoracion.data = env;
      state.valoracion.status = {
        loaded: true,
      };
    },
    getValoracionError(state, error){
      state.valoracion.data = null
      state.valoracion.status={
        error
      }
    },
    valoracionesExcelRequest(state){
      state.valoracionesExcel.status={
        loading:true
      }
    },
    valoracionesExcelSuccess(state, pdf){
      state.valoracionesExcel.data = pdf
      state.valoracionesExcel.status={
        loaded:true
      }
    },
    valoracionesExcelError(state, error){
      state.valoracionesExcel.data = null
      state.valoracionesExcel.status={
        error
      }
    }
  },
  actions: {
    getValoraciones({ commit }, req) {
      commit('getValoracionesRequest');
      valoracionService.getValoraciones(req.pagination, req.filters)
        .then((valoraciones) => {
          commit('getValoracionesSuccess', valoraciones);
        })
        .catch((error) => {
          commit('getValoracionesError', error);
        });
    },
    changeValoracion({ commit }, req) {
      commit('putRevaloracionesRequest');
      valoracionService.changeValoraciones(req)
        .then((res) => {
          commit('putRevaloracionesSuccess', res);
        })
        .catch((error) => {
          commit('putRevaloracionesError', error);
        });
    },
    applyValoracion({ commit }, req) {
      commit('putAplicacionesRequest');
      valoracionService.applyValoraciones(req)
        .then((res) => {
          commit('putAplicacionesSuccess', res);
        })
        .catch((error) => {
          commit('putAplicacionesError', error);
        });
    },
    getValoracion({ commit }, envioId) {
      commit('getValoracionRequest');
      valoracionService.getValoracion(envioId)
        .then((env) => {
          commit('getValoracionSuccess', env);
        })
        .catch((error) => {
          commit('getValoracionError', error);
        });
    },
    valoracionesExcel({ commit }, req) {
      commit('valoracionesExcelRequest');
      valoracionService
        .getValoracionesAsExcel(req.filters)
        .then((excel) => {
          commit('valoracionesExcelSuccess', excel);
        })
        .catch((error) => {
          commit('valoracionesExcelError', error);
        });
    },
  },  
};
