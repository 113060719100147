var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      staticClass: "dropdown nav-item",
      class: [{ show: _vm.isOpen }],
      attrs: { "aria-haspopup": "true", "aria-expanded": _vm.isOpen },
      on: { click: _vm.toggleDropDown },
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle nav-link",
          class: { "hide-arrow": _vm.hideArrow },
          attrs: { "data-toggle": "dropdown" },
        },
        [
          _vm._t("title", function () {
            return [
              _c("i", { class: _vm.icon }),
              _c("span", { staticClass: "no-icon" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("b", { staticClass: "caret" }),
            ]
          }),
        ],
        2
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [
            {
              "dropdown-menu-right": _vm.position === "right",
              "dropdown-menu-end": _vm.position !== "right",
            },
            { show: _vm.isOpen },
          ],
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }