//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "@/components/ModalHotakauth.vue";

export default {
  name: "EditPassword",
  components: { Modal },
  data() {
    return {
      general: {
        password: "",
      },
      rules: {
        password: [
          { required: true, message: "Contraseña requerida!", trigger: "blur" },
          {
            min: 5,
            message: "Contraseña debe tener al menos 5 caracteres.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {},
  computed: {
    changePassword() {
      return this.$store.state.user.editPass.status;
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    editedpassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("user/changePassword", this.general);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    changePassword() {
      if (this.$store.state.user.editPass.status.edited) {
        this.notificacion(
          "Correcto!",
          "Tu contraseña ha sido cambiada correctamente!",
          "success"
        );
        this.hideModal();
      } else if (this.$store.state.user.editPass.status.error) {
        this.notificacion(
          "Error!",
          "Ha ocurrido un error!",
          "error"
        );
        this.hideModal();
      }
    },
  },
};
