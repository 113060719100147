import { createAxios } from './axiosHttpMarconi';
import { userService } from './user.service';
export const franquiciaService = {
  getFranquicia,
  getAllClientesFranquicia,
  loadFranquicia,
  getLocalFranquicia,
  reloadFranquicia,
  getClientesAsExcel,
  getDireccionHabitualByFranquicia,
  eliminarDireccionByClienteId,
  updateFranquicia,
  getFranquiciasById,
  getPlantillas,
  eliminarPlantilla,
  updateEnvioProgramado
};

function updateEnvioProgramado(idCliente, idPlantilla, body){
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `/franquicia/${idFranquicia._id}/cliente/${idCliente}/plantilla/${idPlantilla}/scheduler`;
  return axios.post(urlPath, body);
}

function getPlantillas(idCliente, pagination, filters) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `/franquicia/${idFranquicia._id}/cliente/${idCliente}/plantillas`;
  if (pagination!=null) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  }
  if (filters.nombre && filters.nombre != "") {
    urlPath += "&nombre=" + filters.nombre;
  }
  if (filters.resumen && filters.resumen != "") {
    urlPath += "&resumen=" + filters.resumen;
  }
  return axios.get(urlPath);
}

function eliminarPlantilla(idCliente, idPlantilla) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `/franquicia/${idFranquicia._id}/cliente/${idCliente}/plantilla/${idPlantilla}`;
  return axios.delete(urlPath);
} 

function getFranquiciasById(idFranquicia){
  const axios = createAxios();
  let urlPath = `/franquicia/${idFranquicia}`;
  return axios.get(urlPath);
}

function getFranquicia() {
  return new Promise((resolve, reject) => {
    const axios = createAxios();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && userService.esFranquicia(user)) {
      let urlPath = `franquicia/${user.extraData.franquiciaId}`;
      return axios.get(urlPath)
        .then((response) => { resolve(response); })
        .catch((reason) => { reject(reason) });
    } else {
      reject(new Error("Usuario no logado"))
    }
  });
}

function loadFranquicia() {
  return new Promise((resolve, reject) => {
    getFranquicia().then((franquicia) => {
      localStorage.setItem('franquicia', JSON.stringify(franquicia));
      resolve(franquicia);
    }).catch((error) => {
      reject(error);
    });
  });
}

function getLocalFranquicia() {
  return JSON.parse(localStorage.getItem('franquicia'));
}

function reloadFranquicia() {
  setInterval(() => { loadFranquicia(); }, 3600000);
}

function eliminarDireccionByClienteId(body) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `franquicia/${idFranquicia._id}/cliente/${body.clienteId}/contacto/${body.direccionId}`;
  return axios.delete(urlPath);
} 

function getDireccionHabitualByFranquicia(body) {
  const axios = createAxios();
  const idFranquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `franquicia/${idFranquicia._id}/cliente/${body.clienteId}/contacto`;
  if(body.pagination != null) {
    urlPath += `?page=${body.pagination.currentPage - 1}&limit=${body.pagination.perPage}`;
  } else {
    urlPath += '?';
  }
  if (body.tipo) {
    urlPath += `&tipo=${body.tipo}`;
  }
  if (body.defecto) {
    urlPath += `&defecto=${body.defecto}`;
  }
  if (body.contacto) {
    urlPath += `&contacto=${body.contacto}`;
  }
  if (body.direccion) {
    urlPath += `&direccion=${body.direccion}`;
  }
  if (body.normalizada != null) {
    urlPath += `&normalizada=${body.normalizada}`;
  }
  return axios.get(urlPath);
}

function getAllClientesFranquicia(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let parametros = ""
  if (pagination) {
    parametros += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    parametros += '?page=0&limit=10';
  }
  if (filters.nombre && filters.nombre != "") {
    parametros += "&nombre=" + filters.nombre;
  }
  if (filters.alias && filters.alias != "") {
    parametros += "&alias=" + filters.alias;
  }
  if (filters.codigo && filters.codigo != "") {
    parametros += "&codigo=" + filters.codigo;
  }
  if (filters.idFiscal && filters.idFiscal != "") {
    parametros += "&idFiscal=" + filters.idFiscal;
  }
  if (filters && filters.activo) {
    parametros += `&activado=${filters.activo}`;
  }
  const urlPath = `/franquicia/${user.extraData.franquiciaId}/cliente${parametros}`;
  return axios.get(urlPath);
}

function getClientesAsExcel(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let parametros = "?limit=2000"
  if (request.nombre && request.nombre != "") {
    parametros += "&nombre=" + request.nombre;
  }
  if (request.alias && request.alias != "") {
    parametros += "&alias=" + request.alias;
  }
  if (request.codigo && request.codigo != "") {
    parametros += "&codigo=" + request.codigo;
  }
  if (request.idFiscal && request.idFiscal != "") {
    parametros += "&idFiscal=" + request.idFiscal;
  }
  if (typeof request.generico == 'boolean' && request.generico) {
    parametros += "&generico=" + request.generico;
  }
  if (request && request.activo) {
    parametros += `&activado=${request.activo}`;
  }
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/cliente/excel${parametros}`;
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

function updateFranquicia(body) {
  const axios = createAxios();
  let urlPath = `/franquicia/${body._id}`;
  return axios.put(urlPath, body);
}
