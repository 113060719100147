var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table" }, [
    _c(
      "thead",
      [
        _vm._t("columns", function () {
          return _vm._l(_vm.columns, function (column, key) {
            return _c("th", { key: key }, [_vm._v(_vm._s(column))])
          })
        }),
      ],
      2
    ),
    _c(
      "tbody",
      _vm._l(_vm.data, function (item, key) {
        return _c(
          "tr",
          { key: key },
          [
            _vm._t(
              "default",
              function () {
                return [
                  _vm._l(_vm.columns, function (column) {
                    return [
                      _vm.hasValue(item, column)
                        ? _c("td", { key: column }, [
                            _vm._v(_vm._s(_vm.itemValue(item, column))),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ]
              },
              { row: item }
            ),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }