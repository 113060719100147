import createAxios from './axiosHttpMarconi';
export const formasPagoService = {
  getFormasPago
};

function getFormasPago(filters) {
  const axios = createAxios();
  let urlPath = '/formaspago?sort=_id&order=ascending';
  if (filters && filters.codigo) {
    urlPath += `&codigo=${filters.codigo}`
  }
  return axios.get(urlPath);
}