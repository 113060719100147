//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'l-switch',
  props: {
    value: [Array, Boolean],
    onText: String,
    offText: String,
    color: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black'].includes(value)
      }
    }
  },
  computed: {
    switchClass () {
      let base = 'bootstrap-switch-'
      let state = this.model ? 'on' : 'off'
      let classes = base + state
      if (this.color) {
        classes = `${classes} ${base}${this.color}`
      }
      return classes
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    triggerToggle () {
      this.model = !this.model
    }
  }
}
