//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationItem from "./NotificationItem.vue";
export default {
  components: {
    NotificationItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fileStatus() {
      return this.$store.state.dashboard.file.status;
    },
  },
  methods: {
    showFile(blob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob.file);
      link.download = blob.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  watch: {
    fileStatus(val) {
      if (val.downloaded) {
        this.showFile(this.$store.state.dashboard.file.data);
        return;
      }
      if (val.error) {
        this.$notify({
          message: "Ha ocurrido un error la descargar el fichero!",
          type: "danger",
        });
        return;
      }
    },
  },
};
