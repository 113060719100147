//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      isActive: true
    };
  },
  mounted() { },
  computed: {
    statusLogin() {
      return this.$store.state.user.entity.status;
    },
    user() {
      return this.$store.state.user.entity.data;
    },
  },
  methods: {
    submit() {
      const model = {
        user: this.email,
        password: this.password,
      };
      this.isActive = false;
      this.$store.dispatch("user/login", model);
      //this.$router.push( {name: 'dashboard'} );
    },
    notificacion(titl, msg, typ) {
      this.$message({
        title: titl,
        message: msg,
        type: typ,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  watch: {
    statusLogin() {      
      if (this.statusLogin.logged) {
        this.isActive = true;
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes("FRANQUICIA")) {
          this.$router.push("/");
        } else {
          this.notificacion("Error!", "Ha ocurrido un error", "error");
        }
      } else if (this.statusLogin.error) {
        this.isActive = true;
        this.notificacion("Error!", "Error en el usuario o contraseña", "error");
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
