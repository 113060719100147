var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c(
      "div",
      {
        staticClass: "container-fluid d-flex flex-wrap justify-content-between",
      },
      [
        _c("nav", [
          _c("ul", { staticClass: "footer-menu" }, [
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: { path: "/" } } }, [
                  _vm._v("Dashboard"),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "copyright" }, [
          _vm._v(" " + _vm._s(_vm.version) + " "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }