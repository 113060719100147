import { remesaService } from "@/_services";

export const remesa = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    remesa: {
      data: {},
      status: {},
    },
    update:{
      data: {},
      status: {},
    },
    remesado:{
      data: {},
      status: {},
    },
    liberado:{
      data: {},
      status: {},
    },
    new: {
      data: {},
      status: {},
    },
    eliminar: {
      data: {},
      status: {},
    },
    aprobar: {
      data: {},
      status: {},
    },
    sepa: {
      data: null,
      status: {}
    }
  },
  mutations: {
    eliminarRemesaRequest(state) {
      state.eliminar.status = {
        loading: true
      }
    },
    eliminarRemesaSuccess(state, response) {
      state.eliminar.data = response
      state.eliminar.status = {
        loaded: true
      }
    },
    eliminarRemesaError(state, error) {
      state.eliminar.data = null
      state.eliminar.status = {
        error
      }
    },
    getRemesasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getRemesasSuccess(state, remesas) {
      state.list.data = remesas;
      state.list.status = {
        loaded: true,
      };
    },
    getRemesasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getRemesaByIdRequest(state) {
      state.remesa.status = {
        loading: true,
      };
    },
    getRemesaByIdSuccess(state, remesa) {
      state.remesa.data = remesa;
      state.remesa.status = {
        loaded: true,
      };
    },
    getRemesaByIdError(state, error) {
      state.remesa.data = null;
      state.remesa.status = {
        error,
      };
    },
    updateRemesaRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateRemesaSuccess(state, remesa) {
      state.update.data = remesa;
      state.update.status = {
        loaded: true,
      };
    },
    updateRemesaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    remesarRemesaRequest(state) {
      state.remesado.status = {
        loading: true,
      };
    },
    remesarRemesaSuccess(state, remesa) {
      state.remesado.data = remesa;
      state.remesado.status = {
        loaded: true,
      };
    },
    remesarRemesaError(state, error) {
      state.remesado.data = null;
      state.remesado.status = {
        error,
      };
    },
    liberarRemesaRequest(state) {
      state.liberado.status = {
        loading: true,
      };
    },
    liberarRemesaSuccess(state, remesa) {
      state.liberado.data = remesa;
      state.liberado.status = {
        loaded: true,
      };
    },
    liberarRemesaError(state, error) {
      state.liberado.data = null;
      state.liberado.status = {
        error,
      };
    },
    newRemesaRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newRemesaError(state, error) {
      state.new.data = null;
      state.new.status = {
        error,
      };
    },
    newRemesaSuccess(state, cliente) {
      state.new.data = cliente;
      state.new.status = {
        created: true,
      };
    },
    aprobarRemesaRequest(state) {
      state.aprobar.status = {
        creating: true,
      };
    },
    aprobarRemesaError(state, error) {
      state.aprobar.data = null;
      state.aprobar.status = {
        error,
      };
    },
    aprobarRemesaSuccess(state, rem) {
      state.aprobar.data = rem;
      state.aprobar.status = {
        updated: true,
      };
    },
    remesaSepaRequest(state) {
      state.sepa.status = {
        downloading: true
      }
    },
    remesaSepaSuccess(state, xml) {
      state.sepa.data = xml
      state.sepa.status = {
        downloaded: true
      }
    },
    remesaSepaError(state, error) {
      state.sepa.data = null
      state.sepa.status = {
        error
      }
    }  
  },
  actions: {
    nuevaRemesa({ commit }, body) {
      commit('newRemesaRequest');
      remesaService.nuevaRemesa(body)
        .then((remesa) => {
          commit('newRemesaSuccess', remesa);
        })
        .catch((error) => {
          commit('newRemesaError', error);
        });
    },
    aprobarRemesa({ commit }, req) {
      commit('aprobarRemesaRequest');
      remesaService.aprobarRemesa(req.idRemesa, req.fechaAprobacion)
        .then((remesa) => {
          commit('aprobarRemesaSuccess', remesa);
        })
        .catch((error) => {
          commit('aprobarRemesaError', error);
        });
    },
    getRemesas({ commit }, req) {
      commit("getRemesasRequest");
      remesaService
        .getRemesas(req.pagination, req.filters)
        .then((remesas) => {
          commit("getRemesasSuccess", remesas);
        })
        .catch((error) => {
          commit("getRemesasError", error);
        });
    },
    getRemesaById({ commit }, req) {
      commit("getRemesaByIdRequest");
      remesaService
        .getRemesaById(req)
        .then((remesa) => {
          commit("getRemesaByIdSuccess", remesa);
        })
        .catch((error) => {
          commit("getRemesaByIdError", error);
        });
    },
    updateRemesa({ commit }, body) {
      commit("updateRemesaRequest");
      remesaService
        .updateRemesa(body)
        .then((remesa) => {
          commit("updateRemesaSuccess", remesa);
        })
        .catch((error) => {
          commit("updateRemesaError", error);
        });
    },
    remesarRemesa({ commit }, body){
      commit("remesarRemesaRequest");
      remesaService
        .remesarRemesa(body)
        .then((remesa) => {
          commit("remesarRemesaSuccess", remesa);
        })
        .catch((error) => {
          commit("remesarRemesaError", error);
        });
    },
    liberarRemesa({ commit }, body){
      commit("liberarRemesaRequest");
      remesaService
        .liberarRemesa(body)
        .then((remesa) => {
          commit("liberarRemesaSuccess", remesa);
        })
        .catch((error) => {
          commit("liberarRemesaError", error);
        });
    },
    eliminarRemesa({ commit }, idRemesa) {
      commit('eliminarRemesaRequest');
      remesaService
        .eliminarRemesa(idRemesa)
        .then((remesa) => {
          commit('eliminarRemesaSuccess', remesa);
        })
        .catch((error) => {
          commit('eliminarRemesaError', error);
        });
    },
    getSepa({ commit }, filters) {
      commit('remesaSepaRequest');
      remesaService
        .getSepa(filters)
        .then((xml) => {
          commit('remesaSepaSuccess', xml);
        })
        .catch((error) => {
          commit('remesaSepaError', error);
        });
    },
  },
}
