var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal-mask" }, [
    _c("div", { staticClass: "modal-dialog modal-wrapper" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "modal-content modal-container",
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              { staticClass: "modal-title" },
              [
                _vm._t("header", function () {
                  return [_vm._v(" Warning ")]
                }),
              ],
              2
            ),
            _c("i", {
              staticClass:
                "el-icon-close position-absolute top-0 end-0 mt-3 me-3",
              on: { click: _vm.hideModal },
            }),
          ]),
          _vm._t("sub-header"),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", function () {
                return [_vm._v(" Seguro desea realizar esta acción ")]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer", function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("hide")
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("accepted")
                        },
                      },
                    },
                    [_vm._v(" Aceptar ")]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }