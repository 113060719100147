import { recogidaProgramadaService } from "@/_services";

export const recogidaProgramada = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
    schedule: {
      data: {},
      status: {},
    },
    delete: {
      data: {},
      status: {},
    },
    create: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getRecogidasProgramadasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getRecogidasProgramadasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getRecogidasProgramadasSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    scheduleRecogidaProgramadaRequest(state) {
      state.schedule.status = {
        scheduling: true,
      };
    },
    scheduleRecogidaProgramadaError(state, error) {
      state.schedule.data = null;
      state.schedule.status = {
        error,
      };
    },
    scheduleRecogidaProgramadaSuccess(state, data) {
      state.schedule.data = data;
      state.schedule.status = {
        scheduled: true,
      };
    },
    eliminarRecogidaProgramadaRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    eliminarRecogidaProgramadaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    eliminarRecogidaProgramadaSuccess(state, data) {
      state.delete.data = data;
      state.delete.status = {
        deleted: true,
      };
    },
    getRecogidaProgramadaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getRecogidaProgramadaByIdSuccess(state, env) {
      state.entity.data = env;
      state.entity.status = {
        loaded: true,
      };
    },
    getRecogidaProgramadaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    createRecogidaProgramadaRequest(state) {
      state.create.status = {
        creating: true,
      };
    },
    createRecogidaProgramadaSuccess(state, env) {
      state.create.data = env;
      state.create.status = {
        created: true,
      };
    },
    createRecogidaProgramadaError(state, error) {
      state.create.data = null;
      state.create.status = {
        error,
      };
    },
  },
  actions: {
    getRecogidasProgramadas({ commit }, req) {
      commit("getRecogidasProgramadasRequest");
      recogidaProgramadaService
        .getRecogidasProgramadas(req.pagination, req.filters)
        .then((recogidas) => {
          commit("getRecogidasProgramadasSuccess", recogidas);
        })
        .catch((error) => {
          commit("getRecogidasProgramadasError", error);
        });
    },
    scheduleRecogidaProgramada({ commit }, req) {
      commit("scheduleRecogidaProgramadaRequest");
      recogidaProgramadaService
        .scheduleRecogidaProgramada(req.idRecogida, req.body)
        .then((res) => {
          commit("scheduleRecogidaProgramadaSuccess", res);
        })
        .catch((error) => {
          commit("scheduleRecogidaProgramadaError", error);
        });
    },
    eliminarRecogidaProgramada({ commit }, req) {
      commit("eliminarRecogidaProgramadaRequest");
      recogidaProgramadaService
        .eliminarRecogidaProgramada(req)
        .then((res) => {
          commit("eliminarRecogidaProgramadaSuccess", res);
        })
        .catch((error) => {
          commit("eliminarRecogidaProgramadaError", error);
        });
    },
    getRecogidaProgramadaById({ commit }, recogidaId) {
      commit("getRecogidaProgramadaByIdRequest");
      recogidaProgramadaService
        .getRecogidaProgramadaById(recogidaId)
        .then((response) => {
          commit("getRecogidaProgramadaByIdSuccess", response);
        })
        .catch((error) => {
          commit("getRecogidaProgramadaByIdError", error);
        });
    },
    createRecogidaProgramada({ commit }, recogida) {
      commit("createRecogidaProgramadaRequest");
      recogidaProgramadaService
        .createRecogidaProgramada(recogida)
        .then((response) => {
          commit("createRecogidaProgramadaSuccess", response);
        })
        .catch((error) => {
          commit("createRecogidaProgramadaError", error);
        });
    },
  },
};
