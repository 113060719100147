import { estadoService } from '@/_services';

export const estado = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getEstadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEstadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getEstadosSuccess(state, estados) {
      state.list.data = estados;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getEstados({ commit }, req) {
      commit('getEstadosRequest');
      estadoService.getEstados(req)
        .then((estados) => {
          commit('getEstadosSuccess', estados);
        })
        .catch((error) => {
          commit('getEstadosError', error);
        });
    },
  },
};

