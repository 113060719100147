import { apiServerV2 } from "../globalVars";

const axios = require("axios").default;
function createAxiosLogin() {
  const headers = {};
  const instance = axios.create({
    baseURL: apiServerV2,
    headers,
  });

  instance.interceptors.response.use(
    (response) => Promise.resolve(response.data),
    (error) => {
      if (error.response) {
        console.log("response");
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject("Error al acceder a los servicios API");
      }
    }
  );

  return instance;
}
// eslint-disable-next-line no-undef

export default createAxiosLogin;
