var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LineChartGenerator", {
    attrs: {
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chartData,
      "chart-id": _vm.chartId,
      "dataset-id-key": _vm.datasetIdKey,
      plugins: _vm.plugins,
      "css-classes": _vm.cssClasses,
      styles: _vm.styles,
      width: _vm.width,
      height: _vm.height,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }