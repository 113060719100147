import createAxios from './axiosHttpMarconi';
export const vencimientosService = {
  getVencimientos
};

function getVencimientos() {
  const axios = createAxios();
  let urlPath = '/vencimientos?sort=_id&order=ascending';

  return axios.get(urlPath);
}