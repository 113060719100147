//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    loading:{
      required: false,
      default: () => false
    }
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
  },
};
