//
//
//
//
//
//

export default {
  props: {
    filtrosProp: {
      type: Object,
    },
  },
  data() {
    return {
      datos: JSON.stringify(this.filtrosProp),
      filtros: [],
      size: "small",
    };
  },
  computed: {
    texto() {
      let filtros = "";
      for (let n = 0; n < this.filtros.length; n += 1) {
        let label = this.filtros[n].label.replace(/([A-Z])/g, " $1");
        filtros +=
          `${label[0].toUpperCase()}${label.slice(1)}: ${
            this.filtros[n].value
          }` + "     ";
      }
      return filtros;
    },
  },
  mounted() {
    for (const prope in this.filtrosProp) {
      this.filtros.push({ label: prope, value: this.filtrosProp[prope] });
    }
  },
  watch: {
    filtrosProp() {
      this.filtros = [];
      for (const prope in this.filtrosProp) {
        this.filtros.push({ label: prope, value: this.filtrosProp[prope] });
      }
    },
  },
};
