import { efectoService } from "@/_services";

export const efecto = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: []
    },
    edit: {
      data: {},
      status: []
    },
    impagar: {
      data: {},
      status: []
    },
    efectoById: {
      data: {},
      status: []
    },
    liquidar: {
      data: {},
      status: []
    },
    efectosExcel: {
      data: null,
      status: {}
    },
    agrupar: {
      data: {},
      status: []
    }
  },
  mutations: {
    getEfectoByIdRequest(state) {
      state.efectoById.status = {
        loading: true,
      };
    },
    getEfectoByIdError(state, error) {
      state.efectoById.data = null;
      state.efectoById.status = {
        error,
      };
    },
    getEfectoByIdSuccess(state, effe) {
      state.efectoById.data = effe;
      state.efectoById.status = {
        loaded: true,
      };
    },
    editEfectoRequest(state) {
      state.edit.status = {
        loading: true,
      };
    },
    editEfectoError(state, error) {
      state.edit.data = null;
      state.edit.status = {
        error,
      };
    },
    editEfectoSuccess(state, effe) {
      state.edit.data = effe;
      state.edit.status = {
        loaded: true,
      }
    },
    agruparEfectoRequest(state) {
      state.edit.status = {
        loading: true,
      };
    },
    agruparEfectoError(state, error) {
      state.agrupar.data = null;
      state.agrupar.status = {
        error,
      };
    },
    agruparEfectoSuccess(state, effe) {
      state.agrupar.data = effe;
      state.agrupar.status = {
        loaded: true,
      }
    },
    impagarEfectoRequest(state) {
      state.impagar.status = {
        loading: true,
      };
    },
    impagarEfectoError(state, error) {
      state.impagar.data = null;
      state.impagar.status = {
        error,
      };
    },
    impagarEfectoSuccess(state, imp) {
      state.impagar.data = imp;
      state.impagar.status = {
        loaded: true,
      }
    },
    getEfectosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEfectosSuccess(state, efectos) {
      state.list.data = efectos;
      state.list.status = {
        loaded: true,
      };
    },
    getEfectosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    liquidarEfectosRequest(state) {
      state.liquidar.status = {
        loading: true,
      };
    },
    liquidarEfectosSuccess(state, efectos) {
      state.liquidar.data = efectos;
      state.liquidar.status = {
        loaded: true,
      };
    },
    liquidarEfectosError(state, error) {
      state.liquidar.data = [];
      state.liquidar.status = {
        error,
      };
    },
    efectosExcelRequest(state) {
      state.efectosExcel.status = {
        loading: true
      }
    },
    efectosExcelSuccess(state, pdf) {
      state.efectosExcel.data = pdf
      state.efectosExcel.status = {
        loaded: true
      }
    },
    efectosExcelError(state, error) {
      state.efectosExcel.data = null
      state.efectosExcel.status = {
        error
      }
    }    
  },
  actions: {
    getEfectoById({ commit }, efectoId) {
      commit('getEfectoByIdRequest');
      efectoService.getEfectoById(efectoId)
        .then((efecto) => {
          commit('getEfectoByIdSuccess', efecto);
        })
        .catch((error) => {
          commit('getEfectoByIdError', error);
        });
    },
    editEfecto({ commit }, req) {
      commit('editEfectoRequest');
      efectoService.editEfecto(req.efectoId, req.body)
        .then((response) => {
          commit('editEfectoSuccess', response);
        })
        .catch((error) => {
          commit('editEfectoError', error);
        });
    },
    agruparEfecto({ commit }, body) {
      commit('agruparEfectoRequest');
      efectoService.agruparEfecto(body)
        .then((response) => {
          commit('agruparEfectoSuccess', response);
        })
        .catch((error) => {
          commit('agruparEfectoError', error);
        });
    },
    impagarEfecto({ commit }, req) {
      commit('impagarEfectoRequest');
      efectoService.impagarEfecto(req.efectoId, req.body)
        .then((response) => {
          commit('impagarEfectoSuccess', response);
        })
        .catch((error) => {
          commit('impagarEfectoError', error);
        });
    },
    getEfectos({ commit }, req) {
      commit("getEfectosRequest");
      efectoService
        .getEfectos(req.pagination, req.filters)
        .then((efectos) => {
          commit("getEfectosSuccess", efectos);
        })
        .catch((error) => {
          commit("getEfectosError", error);
        });
    },
    liquidarEfectos({ commit }, req) {
      commit("liquidarEfectosRequest");
      efectoService
        .liquidarEfectos(req)
        .then((response) => {
          commit("liquidarEfectosSuccess", response);
        })
        .catch((error) => {
          commit("liquidarEfectosError", error);
        });
    },
    efectosExcel({ commit }, request) {
      commit('efectosExcelRequest');
      efectoService
        .getEfectosAsExcel(request)
        .then((excel) => {
          commit('efectosExcelSuccess', excel);
        })
        .catch((error) => {
          commit('efectosExcelError', error);
        });
    },
  },
};
