import createAxios from "./axiosHttpMarconi";

function getAlbaranes(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaranes`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=20";
  }

  urlPath += getFilters(filters);

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }

  return axios.get(urlPath);
}
function getConceptos() {
  const axios = createAxios();
  let urlPath = `/conceptos?sort=nombre&order=ascending`;
  return axios.get(urlPath);
}
function nuevoAlbaran(albaran) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  //albaran.franquicia=user.extraData.franquiciaId;
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaran`;
  return axios.post(urlPath, albaran);
}
function editAlbaran(albaran) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaran/${albaran._id}`;
  return axios.put(urlPath, albaran);
}
function getFichaAlbaran(idAlbaran) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaran/${idAlbaran}`;
  return axios.get(urlPath);
}

function actionAlbaran(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaranes/estado`;
  return axios.put(urlPath, req);
}

function prefacturarAlbaranesGenericos(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaranes/generico`;
  return axios.put(urlPath, req);
}

function getAlbaranesAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/albaranes/excel?limit=2000`;

  urlPath += getFilters(filters);

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }

  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&fechaDesde=${filters.date[0]}`;
    urlPath += `&fechaHasta=${filters.date[1]}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.numero) {
    urlPath += `&numero=${filters.numero}`;
  }

  if (filters && filters.numeroFactura) {
    urlPath += `&numeroFactura=${filters.numeroFactura}`;
  }

  if (filters && filters.manuales) {
    urlPath += `&manuales=${filters.manuales}`;
  }
  if (
    filters &&
    filters.valoracionAplicada &&
    filters.valoracionAplicada.length > 0
  ) {
    urlPath += `&valoracionAplicada=${filters.valoracionAplicada}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.estadoEnvio && filters.estadoEnvio.length > 0) {
    urlPath += `&estadoEnvio=${filters.estadoEnvio.join()}`;
  }
  return urlPath;
}

export const albaranService = {
  getAlbaranes,
  getConceptos,
  nuevoAlbaran,
  actionAlbaran,
  getFichaAlbaran,
  editAlbaran,
  getAlbaranesAsExcel,
  prefacturarAlbaranesGenericos,
};
