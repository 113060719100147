var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.baseComponent,
    {
      tag: "component",
      staticClass: "nav-item",
      class: { active: _vm.isActive },
      attrs: { to: _vm.link.path ? _vm.link.path : "/", tag: "li" },
    },
    [
      _vm.isMenu
        ? _c(
            "a",
            {
              staticClass: "nav-link sidebar-menu-item",
              attrs: {
                "aria-expanded": !_vm.collapsed,
                "data-toggle": "collapse",
              },
              on: { click: _vm.collapseMenu },
            },
            [
              _c("i", { class: _vm.link.icon }),
              _c("p", { class: _vm.hideItem }, [
                _vm._v(" " + _vm._s(_vm.link.name) + " "),
                _c("b", { staticClass: "caret" }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.$slots.default || this.isMenu
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed,
                  expression: "!collapsed",
                },
              ],
            },
            [
              _c("el-collapse-transition", [
                _c(
                  "ul",
                  { staticClass: "nav", on: { click: _vm.resetFilters } },
                  [_vm._t("default")],
                  2
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.children.length === 0 && !_vm.$slots.default && _vm.link.path
        ? _vm._t("title", function () {
            return [
              _c(
                _vm.elementType(_vm.link, false),
                {
                  tag: "component",
                  staticClass: "nav-link",
                  class: { active: _vm.link.active },
                  attrs: {
                    to: _vm.link.path,
                    target: _vm.link.target,
                    href: _vm.link.path,
                  },
                  on: {
                    click: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.linkClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.addLink
                    ? [
                        _c("span", { staticClass: "sidebar-mini" }, [
                          _vm._v(_vm._s(_vm.link.name.substring(0, 1))),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "sidebar-normal",
                            class: _vm.hideItem,
                          },
                          [_vm._v(_vm._s(_vm.link.name))]
                        ),
                      ]
                    : [
                        _c("i", { class: _vm.link.icon }),
                        _c("p", { class: _vm.hideItem }, [
                          _vm._v(_vm._s(_vm.link.name)),
                        ]),
                      ],
                ],
                2
              ),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }