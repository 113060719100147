var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v("100 Awesome Nucleo Icons"),
            ]),
            _c("p", { staticClass: "card-category" }, [
              _vm._v("Handcrafted by our friends from "),
              _c("a", { attrs: { href: "https://nucleoapp.com/?ref=1712" } }, [
                _vm._v("NucleoApp"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body all-icons" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-air-baloon" }),
                    _c("p", [_vm._v("nc-air-baloon")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-album-2" }),
                    _c("p", [_vm._v("nc-album-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-alien-33" }),
                    _c("p", [_vm._v("nc-alien-33")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-align-center" }),
                    _c("p", [_vm._v("nc-align-center")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-align-left-2" }),
                    _c("p", [_vm._v("nc-align-left-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-ambulance" }),
                    _c("p", [_vm._v("nc-ambulance")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-android" }),
                    _c("p", [_vm._v("nc-android")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-app" }),
                    _c("p", [_vm._v("nc-app")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-apple" }),
                    _c("p", [_vm._v("nc-apple")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-atom" }),
                    _c("p", [_vm._v("nc-atom")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-attach-87" }),
                    _c("p", [_vm._v("nc-attach-87")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-audio-92" }),
                    _c("p", [_vm._v("nc-audio-92")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-backpack" }),
                    _c("p", [_vm._v("nc-backpack")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-badge" }),
                    _c("p", [_vm._v("nc-badge")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bag" }),
                    _c("p", [_vm._v("nc-bag")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bank" }),
                    _c("p", [_vm._v("nc-bank")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-battery-81" }),
                    _c("p", [_vm._v("nc-battery-81")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bell-55" }),
                    _c("p", [_vm._v("nc-bell-55")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bold" }),
                    _c("p", [_vm._v("nc-bold")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bulb-63" }),
                    _c("p", [_vm._v("nc-bulb-63")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bullet-list-67" }),
                    _c("p", [_vm._v("nc-bullet-list-67")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-bus-front-12" }),
                    _c("p", [_vm._v("nc-bus-front-12")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-button-pause" }),
                    _c("p", [_vm._v("nc-button-pause")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-button-play" }),
                    _c("p", [_vm._v("nc-button-play")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-button-power" }),
                    _c("p", [_vm._v("nc-button-power")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-camera-20" }),
                    _c("p", [_vm._v("nc-camera-20")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-caps-small" }),
                    _c("p", [_vm._v("nc-caps-small")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-cart-simple" }),
                    _c("p", [_vm._v("nc-cart-simple")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-cctv" }),
                    _c("p", [_vm._v("nc-cctv")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-chart-bar-32" }),
                    _c("p", [_vm._v("nc-chart-bar-32")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-chart-pie-35" }),
                    _c("p", [_vm._v("nc-chart-pie-35")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-chart-pie-36" }),
                    _c("p", [_vm._v("nc-chart-pie-36")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-chart" }),
                    _c("p", [_vm._v("nc-chart")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-chat-round" }),
                    _c("p", [_vm._v("nc-chat-round")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-check-2" }),
                    _c("p", [_vm._v("nc-check-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-circle-09" }),
                    _c("p", [_vm._v("nc-circle-09")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-circle" }),
                    _c("p", [_vm._v("nc-circle")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-cloud-download-93" }),
                    _c("p", [_vm._v("nc-cloud-download-93")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-cloud-upload-94" }),
                    _c("p", [_vm._v("nc-cloud-upload-94")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-compass-05" }),
                    _c("p", [_vm._v("nc-compass-05")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-controller-modern" }),
                    _c("p", [_vm._v("nc-controller-modern")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-credit-card" }),
                    _c("p", [_vm._v("nc-credit-card")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-delivery-fast" }),
                    _c("p", [_vm._v("nc-delivery-fast")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-email-83" }),
                    _c("p", [_vm._v("nc-email-83")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-email-85" }),
                    _c("p", [_vm._v("nc-email-85")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-explore-2" }),
                    _c("p", [_vm._v("nc-explore-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-fav-remove" }),
                    _c("p", [_vm._v("nc-fav-remove")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-favourite-28" }),
                    _c("p", [_vm._v("nc-favourite-28")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-globe-2" }),
                    _c("p", [_vm._v("nc-globe-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-grid-45" }),
                    _c("p", [_vm._v("nc-grid-45")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-headphones-2" }),
                    _c("p", [_vm._v("nc-headphones-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-html5" }),
                    _c("p", [_vm._v("nc-html5")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-istanbul" }),
                    _c("p", [_vm._v("nc-istanbul")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-key-25" }),
                    _c("p", [_vm._v("nc-key-25")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-layers-3" }),
                    _c("p", [_vm._v("nc-layers-3")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-light-3" }),
                    _c("p", [_vm._v("nc-light-3")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-lock-circle-open" }),
                    _c("p", [_vm._v("nc-lock-circle-open")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-map-big" }),
                    _c("p", [_vm._v("nc-map-big")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-mobile" }),
                    _c("p", [_vm._v("nc-mobile")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-money-coins" }),
                    _c("p", [_vm._v("nc-money-coins")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-note-03" }),
                    _c("p", [_vm._v("nc-note-03")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-notes" }),
                    _c("p", [_vm._v("nc-notes")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-notification-70" }),
                    _c("p", [_vm._v("nc-notification-70")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-palette" }),
                    _c("p", [_vm._v("nc-palette")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-paper-2" }),
                    _c("p", [_vm._v("nc-paper-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-pin-3" }),
                    _c("p", [_vm._v("nc-pin-3")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-planet" }),
                    _c("p", [_vm._v("nc-planet")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", {
                      staticClass: "nc-icon nc-preferences-circle-rotate",
                    }),
                    _c("p", [_vm._v("nc-preferences-circle-rotate")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-puzzle-10" }),
                    _c("p", [_vm._v("nc-puzzle-10")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-quote" }),
                    _c("p", [_vm._v("nc-quote")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-refresh-02" }),
                    _c("p", [_vm._v("nc-refresh-02")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-ruler-pencil" }),
                    _c("p", [_vm._v("nc-ruler-pencil")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-satisfied" }),
                    _c("p", [_vm._v("nc-satisfied")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-scissors" }),
                    _c("p", [_vm._v("nc-scissors")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-send" }),
                    _c("p", [_vm._v("nc-send")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-settings-90" }),
                    _c("p", [_vm._v("nc-settings-90")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-settings-gear-64" }),
                    _c("p", [_vm._v("nc-settings-gear-64")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-settings-tool-66" }),
                    _c("p", [_vm._v("nc-settings-tool-66")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-simple-add" }),
                    _c("p", [_vm._v("nc-simple-add")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-simple-delete" }),
                    _c("p", [_vm._v("nc-simple-delete")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-simple-remove" }),
                    _c("p", [_vm._v("nc-simple-remove")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-single-02" }),
                    _c("p", [_vm._v("nc-single-02")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-single-copy-04" }),
                    _c("p", [_vm._v("nc-single-copy-04")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-spaceship" }),
                    _c("p", [_vm._v("nc-spaceship")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-square-pin" }),
                    _c("p", [_vm._v("nc-square-pin")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-stre-down" }),
                    _c("p", [_vm._v("nc-stre-down")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-stre-left" }),
                    _c("p", [_vm._v("nc-stre-left")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-stre-right" }),
                    _c("p", [_vm._v("nc-stre-right")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-stre-up" }),
                    _c("p", [_vm._v("nc-stre-up")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-sun-fog-29" }),
                    _c("p", [_vm._v("nc-sun-fog-29")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-support-17" }),
                    _c("p", [_vm._v("nc-support-17")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-tablet-2" }),
                    _c("p", [_vm._v("nc-tablet-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-tag-content" }),
                    _c("p", [_vm._v("nc-tag-content")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-tap-01" }),
                    _c("p", [_vm._v("nc-tap-01")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-time-alarm" }),
                    _c("p", [_vm._v("nc-time-alarm")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-tv-2" }),
                    _c("p", [_vm._v("nc-tv-2")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-umbrella-13" }),
                    _c("p", [_vm._v("nc-umbrella-13")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-vector" }),
                    _c("p", [_vm._v("nc-vector")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-watch-time" }),
                    _c("p", [_vm._v("nc-watch-time")]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "font-icon-list col-lg-2 col-md-3 col-sm-4 col-6",
                },
                [
                  _c("div", { staticClass: "font-icon-detail" }, [
                    _c("i", { staticClass: "nc-icon nc-zoom-split" }),
                    _c("p", [_vm._v("nc-zoom-split")]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }