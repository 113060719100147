var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: {
        "data-color": _vm.backgroundColor,
        "data-active-color": _vm.activeColor,
      },
    },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c("router-link", { staticClass: "nav-item", attrs: { to: "/" } }, [
            _c("a", { staticClass: "logo-mini" }, [
              _c("div", { staticClass: "logo-img" }, [
                _c("img", { attrs: { src: _vm.logo, alt: "logo" } }),
              ]),
            ]),
          ]),
          _c(
            "a",
            { staticClass: "simple-text logo-normal", attrs: { href: "" } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }